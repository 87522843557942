import { FC, FormEvent, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import ModalPhotos from './ModalPhotos';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { useNavigate, useParams } from 'react-router-dom';
import { getListingById } from 'utils/getListings';
import {
	createNewBid,
	getAllBidsForListing,
	getHighestUserBidForListing,
} from 'utils/getBids';
import convertNumbThousand from 'utils/convertNumbThousand';
import { useUser } from 'providers/UserProvider';
import FormItem from 'containers/PageAddListing1/FormItem';
import { uid } from 'uid';
import { SEND_STATES, STATUS } from 'contains/contants';
import { sendMail } from 'utils/sendMail';
import { Helmet } from 'react-helmet';
import { DEMO_CATS_2 } from 'containers/PageHome/PageHome';

export interface ListingDetailPageProps {
	className?: string;
	isPreviewMode?: boolean;
}

const ListingDetailPage: FC<ListingDetailPageProps> = ({
	className = '',
	isPreviewMode,
}) => {
	const { id } = useParams();

	const { user } = useUser();

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [listing, setListing] = useState<any>();

	const [bids, setBids] = useState<Array<any>>([]);

	const [newBid, setNewBid] = useState<number>();

	const [isSending, setIsSending] = useState<string>(SEND_STATES.NONE);

	const fetchBids = async (listingId: string) => {
		const _allBids = await getAllBidsForListing(listingId);
		setBids(_allBids);
	};

	const fetctchUserbid = async (listingId: string) => {
		const _userBid = await getHighestUserBidForListing(listingId, user.id);
		if (_userBid && _userBid.status === STATUS.REVIEW) {
			setIsSending(SEND_STATES.ARRIVED);
		}
	};

	useEffect(() => {
		const fetchListing = async () => {
			setIsLoading(true);
			const _listing = await getListingById(id);
			if (_listing) {
				setListing(_listing);
				if (_listing.userId === user.id) {
					fetchBids(_listing.id);
				} else {
					fetctchUserbid(_listing.id);
				}
				setIsLoading(false);
				setIsSending(SEND_STATES.NONE);
			} else {
				navigate('/panden');
			}
		};

		const fetchListingWithoutUser = async () => {
			setIsLoading(true);
			const _listing = await getListingById(id);
			if (_listing) {
				setListing(_listing);
				setIsLoading(false);
				setIsSending(SEND_STATES.NONE);
			} else {
				navigate('/panden');
			}
		};

		if (user) {
			fetchListing();
		} else {
			fetchListingWithoutUser();
		}
	}, [user]);

	const [isOpen, setIsOpen] = useState(false);
	const [openFocusIndex, setOpenFocusIndex] = useState(0);

	const handleOpenModal = (index: number) => {
		setIsOpen(true);
		setOpenFocusIndex(index);
	};

	const handleCloseModal = () => setIsOpen(false);

	const submitNewBid = async (e: FormEvent) => {
		setIsSending(SEND_STATES.SENDING);
		e.preventDefault();
		const _bid = {
			id: uid(16),
			value: Number(newBid),
			biderId: user.id,
			listingId: listing.id,
			listingUserId: listing.userId,
			created: new Date(),
			status: STATUS.REVIEW,
		};

		const _newBid = await createNewBid(_bid);

		sendMail('admin', {
			subject: 'Een nieuw bieding op ' + listing.title,
			listing_id: listing.id,
			listing_title: listing.title,
			message: `Er is een nieuw bod van €${_newBid.value.toLocaleString()} gedaan en wacht op een review`,
			to_email: 'info@immomystique.be',
		});

		setIsSending(SEND_STATES.ARRIVED);
	};

	const renderSection1 = () => {
		return (
			<div className='listingSection__wrap !space-y-6'>
				{/* 1 */}
				<div className='flex justify-between items-center'>
					{/* <Badge name='Wooden house' /> */}
					<h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
						{listing.title}
					</h2>
					{/* <LikeSaveBtns /> */}
				</div>

				{/* 3 */}
				<div className='flex items-center space-x-4'>
					<span>
						<i className='las la-map-marker-alt'></i>
						<span className='ml-1'> {listing.adress.split(',')[1].trim()}</span>
					</span>
				</div>

				{/* 4 */}
				{/* <div className='flex items-center'>
					<Avatar hasChecked sizeClass='h-10 w-10' radius='rounded-full' />
					<span className='ml-2.5 text-neutral-500 dark:text-neutral-400'>
						Hosted by{' '}
						<span className='text-neutral-900 dark:text-neutral-200 font-medium'>
							Kevin Francis
						</span>
					</span>
				</div> */}

				{/* 5 */}
				<div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

				{/* 6 */}
				<div className='flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300'>
					<div className='flex items-center space-x-1 '>
						<i className=' las la-expand text-2xl '></i>
						<span className=''>{listing.size}m²</span>
					</div>
					<div className='flex items-center space-x-1 '>
						<i className=' las la-home text-2xl '></i>
						<span className=''>{listing.livingspace}m²</span>
					</div>

					<div className='flex items-center space-x-1 '>
						<i className=' las la-bed text-2xl '></i>
						<span className=''>{listing.bedrooms} slaapkamers</span>
					</div>
				</div>
			</div>
		);
	};

	const renderSection2 = () => {
		return (
			<div className='listingSection__wrap'>
				<h2 className='text-2xl font-semibold'>Beschrijving</h2>
				<div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
				<div className='text-neutral-6000 dark:text-neutral-300'>
					{listing.about}
				</div>
			</div>
		);
	};

	const renderSection4 = () => {
		return (
			<div className='listingSection__wrap'>
				{/* HEADING */}
				<div>
					<h2 className='text-2xl font-semibold'>Kamer groottes</h2>
					<span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
						Deze groottes kunnen lichtjes afwijken met het echte
					</span>
				</div>
				<div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
				{/* CONTENT */}
				<div className='flow-root'>
					<div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
						{listing.rooms.map(({ size, name }: any, ind: number) => {
							if (ind % 2 == 0) {
								return (
									<div className='p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg'>
										<span>{name}</span>
										<span>{size}m²</span>
									</div>
								);
							} else {
								return (
									<div className='p-4  flex justify-between items-center space-x-4 rounded-lg'>
										<span>{name}</span>
										<span>{size}m²</span>
									</div>
								);
							}
						})}

						<div className='p-4 flex justify-between items-center space-x-4 rounded-lg' />
					</div>
				</div>
			</div>
		);
	};

	const renderSection8 = () => {
		return (
			<div className='listingSection__wrap'>
				{/* HEADING */}
				<h2 className='text-2xl font-semibold'>Details van de woning</h2>
				<div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
				{/* CONTENT */}
				<div>
					<h4 className='text-lg font-semibold'>Algemeen</h4>
					<div className='mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base'>
						<div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
							<span>Status</span>
							<span className='capitalize'>{listing.generalInfo.state}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3 '>
							<span>Kelder</span>
							<span>{listing.generalInfo.basement ? 'Aanwezig' : 'Geen'}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
							<span>zolder</span>
							<span>{listing.generalInfo.attic ? 'Aanwezig' : 'Geen'}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3'>
							<span>Garage</span>
							<span>{listing.generalInfo.garage ? 'Aanwezig' : 'Geen'}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
							<span>Tuin</span>
							<span>{listing.generalInfo.garden ? 'Aanwezig' : 'Geen'}</span>
						</div>

						{listing.generalInfo.garden && (
							<div className='flex space-x-10 justify-between p-3'>
								<span>Tuin orientatie</span>
								<span className='capitalize'>
									{listing.generalInfo.orientationGarden}
								</span>
							</div>
						)}

						<div
							className={
								listing.generalInfo.garden
									? 'flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'
									: 'flex space-x-10 justify-between p-3'
							}
						>
							<span>Centrale verwarming</span>
							<span>
								{listing.generalInfo.centralHeat ? 'Aanwezig' : 'Geen'}
							</span>
						</div>
					</div>
				</div>
				<div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

				{/* CONTENT */}
				<div>
					<h4 className='text-lg font-semibold'>EPC</h4>
					<div className='mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base'>
						<div className='flex space-x-10 justify-between p-3'>
							<span>EPC Categorie</span>
							<span>{listing.EPC.category}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
							<span>EPC referentie</span>
							<span>{listing.EPC.ref}</span>
						</div>

						<div className='flex space-x-10 justify-between p-3'>
							<span>EPC-waarde</span>
							<span>{listing.EPC.value} kWh/m²</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderSidebar = () => {
		return (
			<div className='listingSectionSidebar__wrap shadow-xl'>
				{/* PRICE */}
				<div className='flex justify-between'>
					<span className='text-3xl font-semibold'>
						{user && user.id === listing.userId
							? 'Biedingen op dit pand'
							: 'Bied op dit pand'}
					</span>
				</div>
				<div className='flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl '></div>

				{/* SUM */}
				{user &&
					user.id === listing.userId &&
					bids.map(({ value, created }: any, index) => (
						<div className='flex flex-col space-y-4'>
							<div
								className={`flex justify-between text-neutral-6000 dark:text-neutral-300 ${
									index === 0 && 'text-2xl'
								}`}
							>
								{created instanceof Date ? (
									<span>
										{created.getDay().toString().padStart(2, '0')}/
										{(created.getMonth() + 1).toString().padStart(2, '0')}/
										{created.getFullYear()}
									</span>
								) : (
									<span>
										{created.toDate().getDay().toString().padStart(2, '0')}/
										{(created.toDate().getMonth() + 1)
											.toString()
											.padStart(2, '0')}
										/{created.toDate().getFullYear()}
									</span>
								)}
								<span>€ {convertNumbThousand(Number(value))}</span>
							</div>
							<div className='border-b border-neutral-200 dark:border-neutral-700'></div>
						</div>
					))}

				{/* SUBMIT */}
				{user ? (
					user.id !== listing.userId &&
					(isSending === SEND_STATES.ARRIVED ? (
						<p className='text-center'>
							We bekijken uw bod en laten u iets weten als deze gereviewed is
						</p>
					) : (
						<form
							className='flex justify-between gap-4'
							onSubmit={(e) => submitNewBid(e)}
						>
							<FormItem className='w-full'>
								<Input
									required
									disabled={isSending === SEND_STATES.SENDING}
									type='number'
									value={newBid}
									changeValue={setNewBid}
									placeholder={'Doe een bod op dit pand'}
								/>
							</FormItem>
							<ButtonPrimary
								type='submit'
								disabled={isSending === SEND_STATES.SENDING}
								loading={isSending === SEND_STATES.SENDING}
							>
								Bieden
							</ButtonPrimary>
						</form>
					))
				) : (
					<>
						<ButtonPrimary href={'/login'}>Bieden</ButtonPrimary>
					</>
				)}
				<p className=' text-sm  w-[75%]'>
					Dit is een niet-bindend bod. Als er een match is brengen we jullie in
					contact.
				</p>
			</div>
		);
	};
	if (isLoading) {
		return <p>Loading</p>;
	} else {
		return (
			<>
				<Helmet>Detail - Immo Mystique</Helmet>
				<div
					className={`ListingDetailPage nc-ListingDetailPage ${className} mt-5`}
					data-nc-id='ListingDetailPage'
				>
					{/* SINGLE HEADER */}
					<>
						<header className='container 2xl:px-14 rounded-md sm:rounded-xl'>
							<div className='relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2'>
								<div
									className='col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
									onClick={() => handleOpenModal(0)}
								>
									<NcImage
										containerClassName='absolute inset-0'
										className='object-cover w-full h-full rounded-md sm:rounded-xl'
										src={`https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/listings%2F${listing.id}%2F${listing.photos[0]}.png?alt=media`}
									/>
									<div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
								</div>
								{listing.photos
									.filter((_: any, i: number) => i >= 1 && i < 5)
									.map((item: any, index: number) => (
										<div
											key={index}
											className={`relative rounded-md sm:rounded-xl overflow-hidden ${
												index >= 3 ? 'hidden sm:block' : ''
											}`}
										>
											<NcImage
												containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
												className='object-cover w-full h-full rounded-md sm:rounded-xl '
												src={
													`https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/listings%2F${listing.id}%2F${item}.png?alt=media` ||
													''
												}
											/>

											{/* OVERLAY */}
											<div
												className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
												onClick={() => handleOpenModal(index + 1)}
											/>
										</div>
									))}

								<div
									className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
									onClick={() => handleOpenModal(0)}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='h-5 w-5'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={1.5}
											d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
										/>
									</svg>
									<span className='ml-2 text-neutral-800 text-sm font-medium'>
										Alle fotos bekijken
									</span>
								</div>
							</div>
						</header>
						{/* MODAL PHOTOS */}
						<ModalPhotos
							imgs={listing.photos}
							listingId={listing.id}
							isOpen={isOpen}
							onClose={handleCloseModal}
							initFocus={openFocusIndex}
							uniqueClassName='nc-ListingDetailPage-modalPhotos'
						/>
					</>

					{/* MAIn */}
					<main className='container relative z-10 mt-11 flex flex-col lg:flex-row '>
						{/* CONTENT */}
						<div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
							{renderSection1()}
							<div className='lg:hidden sm:block flex-grow mt-14 lg:mt-0'>
								<div className='sticky top-28'>{renderSidebar()}</div>
							</div>
							{renderSection2()}
							{/* {renderSection3()} */}
							{renderSection4()}
							{/* {renderSectionCheckIndate()} */}
							{/* {renderSection5()}
						{renderSection6()} */}
							{/* {renderSection7()} */}
							{renderSection8()}
						</div>

						{/* SIDEBAR */}
						<div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
							<div className='sticky top-28'>{renderSidebar()}</div>
						</div>
					</main>

					{/* OTHER SECTION */}
					{!isPreviewMode && (
						<div className='container py-24 lg:py-32'>
							{/* SECTION 1 */}
							<div className='relative py-16'>
								<BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' />
								<SectionSliderNewCategories
									categories={DEMO_CATS_2}
									categoryCardType='card4'
									itemPerRow={4}
									heading='Meer panden'
									subHeading='Naar wat voor pand ben je op zoek?'
									sliderStyle='none'
									uniqueClassName='PageHome_s2'
								/>
							</div>

							{/* SECTION */}
							<SectionSubscribe2 className='pt-24 lg:pt-32' />
						</div>
					)}
				</div>
			</>
		);
	}
};

export default ListingDetailPage;
