import { LISTING_TYPES } from 'contains/contants';
import { useFilter } from 'providers/FilterProvider';
import { useListings } from 'providers/ListingProvider';
import React from 'react';
import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface Heading2Props {
	heading?: ReactNode;
	subHeading?: ReactNode;
	className?: string;
	nrListing?: number;
}

const Heading2: React.FC<Heading2Props> = ({
	className = '',
	heading = 'Panden in Gent',
	subHeading,
	nrListing,
}) => {
	const { filter } = useFilter();
	const { listings } = useListings();

	let [searchParams, setSearchParams] = useSearchParams();

	const getTypeName = (searchType: any) => {
		for (const [key, value] of Object.entries(LISTING_TYPES)) {
			console.log(value.value === searchType);
			if (String(value.value) === String(searchType)) {
				return String(value.name);
			}
		}
	};

	return (
		<div className={`mb-12 lg:mb-16 ${className}`}>
			<h2 className='text-4xl font-semibold'>
				{filter.active
					? `Panden in radius van ${filter.radius}km van ${filter.location.name}`
					: searchParams.get('type')
					? `Panden: ${getTypeName(searchParams.get('type'))}`
					: 'Alle panden'}
			</h2>
			{subHeading ? (
				subHeading
			) : (
				<span className='block text-neutral-500 dark:text-neutral-400 mt-3'>
					{listings.length === 1
						? `${listings.length} pand gevonden`
						: `${listings.length} panden gevonden`}
				</span>
			)}
		</div>
	);
};

export default Heading2;
