import { STATUS } from 'contains/contants';
import {
	collection,
	deleteDoc,
	doc,
	endAt,
	GeoPoint,
	getDoc,
	getDocs,
	orderBy,
	query,
	setDoc,
	startAt,
	where,
} from 'firebase/firestore';
import { DB } from './firestore';

export const getAllListings = async () => {
	const docRef = collection(DB, `listings`);

	const listingsSnap = await getDocs(docRef);
	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			listings.push(group.data());
		});
	}

	return listings;
};

export const getLocationListings = async (location) => {
	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(docRef, where('tags', 'array-contains', location));
	const listingsSnap = await getDocs(lisitngQuery);
	let listings = [];
	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			listings.push(group.data());
		});
	}
	return listings;
};

export const getListings = async (filter) => {
	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(
		docRef,
		where('tags', 'array-contains', filter.location),
		where('price', '>=', filter.price[0]),
		where('price', '<=', filter.price[1])
	);

	const listingsSnap = await getDocs(lisitngQuery);
	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			listings.push(group.data());
		});
	}

	return listings;
};

export const getPriceListings = async (filter) => {
	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(
		docRef,
		where('price', '>=', filter.price[0]),
		where('price', '<=', filter.price[1])
	);

	const listingsSnap = await getDocs(lisitngQuery);
	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			listings.push(group.data());
		});
	}

	return listings;
};

export const getListingById = async (id) => {
	const docRef = doc(DB, `listings`, id);
	const listingsSnap = await getDoc(docRef);
	return listingsSnap.data();
};

export const getListingsByUserId = async (id) => {
	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(
		docRef,
		where('status', '!=', STATUS.REMOVED),
		where('userId', '==', id)
	);
	const listingsSnap = await getDocs(lisitngQuery);

	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			listings.push(group.data());
		});
	}

	return listings;
};

export const getListingInBound = async (bounds) => {
	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(
		docRef,
		where('longitude', '>=', bounds.southwest.lng),
		where('longitude', '<=', bounds.northeast.lng),
		where('status', '==', STATUS.REVIEWED)
		// where('latitude', '<=', bounds.northeast.lat)
	);

	const listingsSnap = await getDocs(lisitngQuery);

	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			const _listing = group.data();

			if (
				_listing.latitude >= bounds.southwest.lat &&
				_listing.latitude <= bounds.northeast.lat
			) {
				listings.push(_listing);
			}
		});
	}
	return listings;
};

export const getListingInBoundCount = async (bounds) => {
	console.log('BOUND', bounds.southwest.lng);

	const docRef = collection(DB, `listings`);

	const lisitngQuery = query(
		docRef,
		where('longitude', '>=', bounds.southwest.lng),
		where('longitude', '<=', bounds.northeast.lng),
		where('status', '==', STATUS.REVIEWED)
		// where('latitude', '<=', bounds.northeast.lat)
	);

	const listingsSnap = await getDocs(lisitngQuery);

	let listings = [];

	if (!listingsSnap.empty) {
		await listingsSnap.forEach(async (group) => {
			const _listing = group.data();

			if (
				_listing.latitude >= bounds.southwest.lat &&
				_listing.latitude <= bounds.northeast.lat
			) {
				listings.push(_listing);
			}
		});
	}

	return listings.length;
};

export const updateListingStatus = async (id, newSatus) => {
	const docRef = doc(DB, `listings`, id);
	await setDoc(
		docRef,
		{ status: newSatus, removedOn: new Date() },
		{ merge: true }
	);
};
