import React, { FC, useEffect } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { TaxonomyType } from 'data/types';
import { Link } from 'react-router-dom';
import convertNumbThousand from 'utils/convertNumbThousand';
import { useFilter } from 'providers/FilterProvider';
import {
	calculateNortheastPosition,
	calculateSouthwestPosition,
} from 'utils/getBound';
import { getListingInBoundCount } from 'utils/getListings';

export interface CardCategory3Props {
	className?: string;
	taxonomy: TaxonomyType;
	isLocationFilter?: boolean;
}

const CardCategory3: FC<CardCategory3Props> = ({
	className = '',
	taxonomy,
	isLocationFilter,
}) => {
	const { count, name, href = '/', thumbnail, latitude, longitude } = taxonomy;
	const { setFilter } = useFilter();

	return (
		<Link
			to={href}
			className={`nc-CardCategory3 flex flex-col ${className}`}
			data-nc-id='CardCategory3'
			onClick={() => {
				if (isLocationFilter) {
					console.log('HI');
					setFilter({
						radius: 20,
						location: {
							name: name,
							latitude: latitude,
							longitude: longitude,
						},
						active: true,
					});
				} else {
					console.log('CLICK');
				}
			}}
		>
			<div
				className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
			>
				<NcImage
					src={thumbnail}
					className='object-cover w-full h-full rounded-2xl'
				/>
				<span className='opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity'></span>
			</div>
			<div className='mt-4 truncate'>
				<h2
					className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
				>
					{name}
				</h2>
				{/* <span
					className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
				>
					{convertNumbThousand(count || 0)} properties
				</span> */}
			</div>
		</Link>
	);
};

export default CardCategory3;
