import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import React from 'react';
import { Link } from 'react-router-dom';

export interface WidgetFooterMenu {
	id: string;
	title: string;
	menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
	{
		id: '4',
		title: '',
		menus: [{ href: '#', label: '' }],
	},
	{
		id: '5',
		title: 'Verkopen',
		menus: [
			{ href: '/login', label: 'Test je huis' },
			{ href: '/ambassadeurs', label: 'Ambassadeurs' },
		],
	},
	{
		id: '1',
		title: 'Ontdekken',
		menus: [
			{ href: '/panden', label: 'Panden' },
			{ href: '/FAQ', label: 'FAQ' },
		],
	},

	{
		id: '2',
		title: 'Immo Mystique',
		menus: [
			{ href: '/over-immomystique', label: 'Over Immo Mystique' },
			{ href: '/contact', label: 'Contact' },
			{ href: '/pers', label: 'Pers' },
			{ href: '/blog', label: 'Blog' },
			{ href: '/why-visie', label: 'Why & visie' },
			{ href: '/privacy-policy', label: 'Privacy Policy' },
			{ href: '/algemene-voorwaarden', label: 'Algemene voorwaarden' },
		],
	},
];

const Footer: React.FC = () => {
	const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
		return (
			<div key={index} className='text-sm'>
				<h2 className='font-semibold text-neutral-700 dark:text-neutral-200'>
					{menu.title}
				</h2>
				<ul className='mt-5 space-y-4'>
					{menu.menus.map((item, index) => (
						<li key={index}>
							<Link
								key={index}
								className='text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white'
								to={item.href}
							>
								{item.label}
							</Link>
						</li>
					))}
				</ul>
			</div>
		);
	};

	return (
		<div className='nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700'>
			<div className='container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 '>
				<div className='grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col'>
					<div className='col-span-2 md:col-span-1'>
						<Logo />
					</div>
					{
						<div className='col-span-2 flex items-center md:col-span-3'>
							<SocialsList1 className='flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start' />
						</div>
					}
				</div>
				{widgetMenus.map(renderWidgetMenuItem)}
			</div>
		</div>
	);
};

export default Footer;
