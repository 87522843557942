import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import PageHome from 'containers/PageHome/PageHome';
import PandenPage from 'containers/PandenPage/PandenPage';
import ListingStayMapPage from 'containers/PandenPage/ListingStayMapPage';
import ListingDetailPage from 'containers/ListingDetailPage/ListingDetailPage';
import DashboardPage from 'containers/DashboardPage/DashboardPage';
import SettingPage from 'containers/SettingPage/SettingPage';
import PageContact from 'containers/PageContact/PageContact';
import PageAbout from 'containers/PageAbout/PageAbout';
import PageAmbassadeurs from 'containers/PageAmbassadeurs/PageAmbassadeurs';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import PageSubcription from 'containers/PageSubcription/PageSubcription';
import BlogPage from 'containers/BlogPage/BlogPage';
import BlogSingle from 'containers/BlogPage/BlogSingle';
import SiteHeader from 'containers/SiteHeader';
import FooterNav from 'components/FooterNav';
import useWindowSize from 'hooks/useWindowResize';
import { FilterProvider } from 'providers/FilterProvider';
import { ListingsProvider } from 'providers/ListingProvider';
import AddListing from 'containers/AddListing/AddListing';
import { ForgotPass } from 'containers/ForgotPass/ForgotPass';
import { FAQ } from 'containers/FAQ/FAQ';
import PersPage from 'containers/Pers/PersPage';

import { CookiesPopUp } from 'components/Cookies/Cookies';
import PrivacyPolicy from 'containers/PrivacyPolicy/PrivacyPolicy';
import AlgemeneVoorwaarden from 'containers/AlgemeneVoorwaarden/AlgemeneVoorwaarden';
import WhyVision from 'containers/WhyVision/WhyVision';
import MakelaarsPage from 'containers/Makelaars/MakelaarsPage';

import { AllRoutes } from './Routes';
import CookiePolicy from 'containers/CookiePolicy/CookiePolicy';

export const pages: Page[] = [
	{ path: '/', exact: true, component: PageHome },
	{ path: '/#', exact: true, component: PageHome },
	//
	{ path: '/panden', component: PandenPage },
	{ path: '/panden-map', component: ListingStayMapPage },
	{ path: '/panden/:id', component: ListingDetailPage },

	{ path: '/pand-toevoegen', component: AddListing },
	//
	{ path: '/pand-edit/:id', component: AddListing },
	//
	//
	{ path: '/dashboard', component: DashboardPage },
	{ path: '/dashboard-settings', component: SettingPage },
	//
	{ path: '/blog', component: BlogPage },
	{ path: '/blog/:id', component: BlogSingle },

	{ path: '/faq', component: FAQ },

	{ path: '/pers', component: PersPage },

	{ path: '/partners', component: MakelaarsPage },
	//
	//
	{ path: '/contact', component: PageContact },
	{ path: '/over-immomystique', component: PageAbout },
	{ path: '/ambassadeurs', component: PageAmbassadeurs },
	{ path: '/signup', component: PageSignUp },
	{ path: '/login', component: PageLogin },
	{ path: '/forgot-pass', component: ForgotPass },
	{ path: '/subscription', component: PageSubcription },

	{ path: '/privacy-policy', component: PrivacyPolicy },
	{ path: '/algemene-voorwaarden', component: AlgemeneVoorwaarden },
	{ path: '/why-visie', component: WhyVision },
	{ path: '/cookie-policy', component: CookiePolicy },
	//
];

const MyRoutes = () => {
	const WIN_WIDTH = useWindowSize().width || window.innerWidth;
	// const navigate = useNavigate();

	// useEffect(() => {
	// 	if (window.location.pathname !== '/ambassadeurs') {
	// 		navigate('/ambassadeurs');
	// 	}
	// }, [window.location.pathname]);

	return (
		<>
			<ScrollToTop />
			<SiteHeader />
			<CookiesPopUp />
			<FilterProvider>
				<ListingsProvider>
					<AllRoutes />
				</ListingsProvider>
			</FilterProvider>
			{WIN_WIDTH < 768 && <FooterNav />}
			<Footer />
		</>
	);
};

export default MyRoutes;
