import { FC, FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link, useNavigate } from 'react-router-dom';
import { uid } from 'uid';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, DB } from 'utils/firestore';
import { doc, setDoc } from 'firebase/firestore';
import { useUser } from 'providers/UserProvider';
import { useCookie } from 'react-use';

export interface PageSignUpProps {
	className?: string;
}

export interface ErrProps {
	type: string;
	err: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
	const [name, setName] = useState<string>('');

	const [email, setEmail] = useState<string>('');

	const [password, setPassword] = useState<string>('');
	const [checkPassword, setCheckPassword] = useState<string>('');

	const [err, setErr] = useState<ErrProps>({ type: 'none', err: 'none' });

	const [isSending, setIsSending] = useState<boolean>(false);

	const { setUser } = useUser();

	const navigate = useNavigate();

	const checkPasswords = () => {
		if (password === checkPassword) {
			return true;
		} else {
			return false;
		}
	};

	const [refToken, setRefToken] = useCookie('referal');

	const submitNewUser = async (e: FormEvent) => {
		e.preventDefault();
		setIsSending(true);
		let _user = {
			name: name,
			email: email,
			joined: new Date(),
			id: uid(18),
			role: 'user',
			img: './defaultImg.png',
			referalCode: refToken,
		};
		if (checkPasswords()) {
			createUserWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					// Signed in
					_user.id = userCredential.user.uid;
					const userRef = doc(DB, 'users', _user.id);
					setDoc(userRef, _user);
					setUser(_user);
					setErr({ type: 'none', err: 'none' });
					setIsSending(false);
					navigate('/dashboard');
					// ...
				})
				.catch((error) => {
					console.log(error.code);
					if (error.code === 'auth/email-already-in-use') {
						setErr({
							type: 'email',
							err: 'Dit email adres is al in gebruik',
						});
					}
					setIsSending(false);
					// ..
				});
		} else {
			setErr({ type: 'password', err: 'Wachtwoorden komen niet overeen' });
			setIsSending(false);
		}
	};

	return (
		<div className={`nc-PageSignUp  ${className}`} data-nc-id='PageSignUp'>
			<Helmet>
				<title>Account maken</title>
			</Helmet>
			<div className='container mb-24 lg:mb-32'>
				<h2 className='my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
					Registreren
				</h2>
				<div className='max-w-md mx-auto space-y-6 '>
					{/* FORM */}
					<form
						className='grid grid-cols-1 gap-6'
						onSubmit={(e) => submitNewUser(e)}
					>
						<label className='block'>
							<span className='text-neutral-800 dark:text-neutral-200'>
								Naam
							</span>
							<Input
								value={name}
								disabled={isSending}
								required
								changeValue={setName}
								type='text'
								placeholder='Volledige naam hier'
								className='mt-1'
							/>
						</label>

						<label className='block'>
							<span className='text-neutral-800 dark:text-neutral-200'>
								Email
							</span>
							<Input
								value={email}
								disabled={isSending}
								required
								changeValue={setEmail}
								type='email'
								placeholder='example@example.be'
								className='mt-1'
							/>
							<p className='text-red-500'>{err.type === 'email' && err.err}</p>
						</label>
						<label className='block'>
							<span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
								Wachtwoord
							</span>
							<Input
								value={password}
								disabled={isSending}
								required
								changeValue={setPassword}
								type='password'
								className='mt-1'
							/>
						</label>

						<label className='block'>
							<span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
								Herhaal wachtwoord
							</span>
							<Input
								value={checkPassword}
								disabled={isSending}
								required
								changeValue={setCheckPassword}
								type='password'
								className='mt-1'
							/>
							<p className='text-red-500'>
								{err.type === 'password' && err.err}
							</p>
						</label>
						<ButtonPrimary
							disabled={isSending}
							loading={isSending}
							type='submit'
						>
							Continue
						</ButtonPrimary>
					</form>

					{/* ==== */}
					<span className='block text-center text-neutral-700 dark:text-neutral-300'>
						Hebt u al een account? {` `}
						<Link className='text-green-500' to='/login'>
							Log in
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PageSignUp;
