import NcImage from 'shared/NcImage/NcImage';
import { Helmet } from 'react-helmet';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useState } from 'react';
import ModalPhotos from 'containers/ListingDetailPage/ModalPhotos';

const PersPage = () => {
	const handleOpenModal = (index: number) => {
		setIsOpen(true);
		setOpenFocusIndex(index);
	};

	const [isOpen, setIsOpen] = useState(false);
	const [openFocusIndex, setOpenFocusIndex] = useState(0);

	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Pers
					</h1>
					<span className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
						Welkom op de perspagina van Immo Mystique
					</span>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Over Immo Mystique</h3>
					<p>
						De vastgoedmarkt extra activeren is onze drijfveer. Panden op het
						Immo Mystique platform zijn op dit ogenblik nog niet Te Koop maar
						wel Koopbaar. We bieden eigenaars van vastgoed de mogelijkheid om op
						een anonieme wijze te achterhalen welke waarde ze kunnen krijgen
						voor hun eigendom.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Pescontact</h3>
					<p>Voor alle media- en persvragen kan u contact opnemen met:</p>
					<div className=' flex flex-col gap-0'>
						<p>
							Naam: Egon Gevaert <br />
							Functie: Bestuurder
							<br />
							E-mail:{' '}
							<a href='mailto:egon@immomystique.be'>egon@immomystique.be</a>
						</p>
					</div>
				</div>
			</>
		);
	};

	const photos = [
		'forsale-house.png',
		'Home-key.jpg',
		'door-key.png',
		'egon.png',
		'niels.png',
	];
	const handleCloseModal = () => setIsOpen(false);

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16 '>
			<Helmet>
				<title>Pers</title>
			</Helmet>
			{renderHeader()}
			<NcImage
				className='w-full rounded-xl object-none h-[720px] '
				containerClassName='container my-10 sm:my-12 '
				src={'/forsale-house.png'}
			/>

			<div className='nc-SingleContent container space-y-10'>
				{renderContent()}
				{/* {renderTags()} */}
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
				{/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}
			</div>
			<div className=' flex  w-full mb-14 '>
				<a className='m-auto' href='/ImmoMystique_assets.zip' download>
					<ButtonPrimary>Download bestanden</ButtonPrimary>
				</a>
			</div>
			<div className=' !max-w-screen-md mx-auto mb-14'>
				<div className='relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 '>
					<div
						className='col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
						onClick={() => handleOpenModal(0)}
					>
						<NcImage
							containerClassName='absolute inset-0'
							className='object-cover w-full h-full rounded-md sm:rounded-xl'
							src={`./ImmoMystique_assets/${photos[0]}` || ''}
						/>
						<div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
					</div>
					{photos
						.filter((_: any, i: number) => i >= 1 && i < 5)
						.map((item: any, index: number) => (
							<div
								key={index}
								className={`relative rounded-md sm:rounded-xl overflow-hidden  ${
									index >= 3 ? 'hidden sm:block' : ''
								}`}
							>
								<NcImage
									containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
									className='object-cover w-full h-full rounded-md sm:rounded-xl '
									src={`./ImmoMystique_assets/${item}` || ''}
								/>

								{/* OVERLAY */}
								<div
									className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
									onClick={() => handleOpenModal(index + 1)}
								/>
							</div>
						))}

					<div
						className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
						onClick={() => handleOpenModal(0)}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-5 w-5'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={1.5}
								d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
							/>
						</svg>
						<span className='ml-2 text-neutral-800 text-sm font-medium'>
							Alle fotos bekijken
						</span>
					</div>
				</div>

				<ModalPhotos
					imgs={photos}
					listingId={undefined}
					isOpen={isOpen}
					onClose={handleCloseModal}
					initFocus={openFocusIndex}
					uniqueClassName='nc-ListingDetailPage-modalPhotos'
				/>
			</div>
		</div>
	);
};

export default PersPage;
