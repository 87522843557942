import React, { FC } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import StartRating from 'components/StartRating/StartRating';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import SaleOffBadge from 'components/SaleOffBadge/SaleOffBadge';
import Badge from 'shared/Badge/Badge';

export interface StayCardHProps {
	className?: string;
	data?: any;
}

const StayCardH: FC<StayCardHProps> = ({ className = '', data }) => {
	const renderSliderGallery = () => {
		return (
			<div className='relative flex-shrink-0 w-full md:w-72 '>
				<GallerySlider
					ratioClass='aspect-w-6 aspect-h-5'
					galleryImgs={data.photos}
					uniqueID={`StayCardH_${data.id}`}
					href={`/panden/${data.id}`}
					listingId={data.id}
				/>
				{/* <BtnLikeIcon isLiked={false} className='absolute right-3 top-3' /> */}
			</div>
		);
	};

	const renderTienIch = () => {
		return (
			<div className='hidden sm:flex justify-between max-w-sm'>
				<div className='flex items-center space-x-3'>
					<i className='las la-expand text-lg'></i>
					<span className='text-sm text-neutral-500 dark:text-neutral-400'>
						{data.size} m²
					</span>
				</div>

				<div className='flex items-center space-x-3'>
					<i className='las la-home text-lg'></i>
					<span className='text-sm text-neutral-500 dark:text-neutral-400'>
						{data.livingspace} m²
					</span>
				</div>

				<div className='flex items-center space-x-3'>
					<i className='las la-bed text-lg'></i>
					<span className='text-sm text-neutral-500 dark:text-neutral-400'>
						{data.bedrooms} x
					</span>
				</div>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div className='flex-grow p-3 sm:p-5 flex flex-col'>
				<div className='space-y-2'>
					<div className='text-sm text-neutral-500 dark:text-neutral-400'>
						<span>in {data.adress.split(',')[1].trim()}</span>
					</div>
					<div className='flex items-center space-x-2'>
						<h2 className='text-lg font-medium capitalize'>
							<span className='line-clamp-1'>{data.title}</span>
						</h2>
					</div>
				</div>
				<div className='hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4'></div>
				{renderTienIch()}
				<div className='w-14 border-b border-neutral-100 dark:border-neutral-800 my-4'></div>
				<p className='line-clamp-3'>{data.about}</p>
				{/* <div className='flex justify-between items-end'>
					<StartRating reviewCount={10} point={5} />
					<span className='text-base font-semibold text-secondary-500'>
						€ {data.price}
					</span>
				</div> */}
			</div>
		);
	};

	return (
		<div
			className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
			data-nc-id='StayCardH'
		>
			<Link to={`/panden/${data.id}`} className='absolute inset-0'></Link>
			<div className='grid grid-cols-1 md:flex md:flex-row '>
				{renderSliderGallery()}
				{renderContent()}
			</div>
		</div>
	);
};

export default StayCardH;
