import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { Helmet } from 'react-helmet';
import NcImage from 'shared/NcImage/NcImage';
import HIW1img from 'images/howWorks/OnlineIllustration.svg';
import HIW2img from 'images/howWorks/ProfIllustration.svg';
import SectionFounder from 'containers/PageAbout/SectionFounder';

const WhyVision = () => {
	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-center text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Why & Visie
					</h1>
					{/* <span className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
						turpis molestie, dictum est a, mattis tellus. Sed dignissim.
					</span> */}

					{/* <div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div> */}
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div className=' flex gap-36 flex-col md:flex-row'>
					<div className=' w-1/2 text-center prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark '>
						<NcImage
							containerClassName='hidden dark:block mb-8 max-w-[200px] mx-auto'
							src={HIW1img}
						/>

						<h3 className=' text-center'>Why</h3>
						<p>
							De waarde van vastgoed blijft constant fluctueren. Immo Mystique
							geeft je een ruimte om af te toetsen wat de huidige marktwaarde
							van je vastgoed is zodat je de juiste financiële beslissing kan
							maken.
						</p>
					</div>

					<div className=' w-1/2 text-center prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark '>
						<NcImage
							containerClassName='hidden dark:block mb-8 max-w-[200px] mx-auto'
							src={HIW2img}
						/>

						<h3 className='text-center'>Visie</h3>
						<p>
							Immo Mystique heeft als doelstelling om de vastgoedmarkt met 1 %
							extra te activeren. Meer opportuniteiten voor kopers
						</p>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16 '>
			<Helmet>
				<title>Why & Visie</title>
			</Helmet>
			{renderHeader()}

			<div className='nc-SingleContent container space-y-10 mt-3 mb-52'>
				{renderContent()}
				{/* {renderTags()} */}
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
				<SectionFounder />
			</div>
		</div>
	);
};

export default WhyVision;
