import rightImg from 'images/about-hero-right.png';
import React, { FC } from 'react';
import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionHero from './SectionHero';
import SectionClientSay from 'components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
	className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
	return (
		<div
			className={`nc-PageAmbassadeur overflow-hidden relative ${className}`}
			data-nc-id='PageAmbassadeur'
		>
			<Helmet>
				<title>Ambassadeur - Immo Mystique</title>
			</Helmet>

			{/* ======== BG GLASS ======== */}
			<BgGlassmorphism />

			<div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
				<SectionHero
					rightImg={rightImg}
					heading='👋 Hi! Wij zijn Immo Mystique.'
					btnText=''
					subHeading='Selling houses in a mysterious way. Test jouw huis op de markt zonder dat er het iemand hoeft te weten.'
				/>
				<div className='relative py-16'>
					<SectionClientSay uniqueClassName='_amasadeur' />
				</div>

				<SectionStatistic />
				<SectionSubscribe2 />
			</div>
		</div>
	);
};

export default PageAbout;
