import { FC, createContext, useState, useContext } from 'react';
import { ReactNode } from 'react';

export interface HeaderListingsProps {
	children: ReactNode;
}

export type ListingsContextType = {
	listings: Array<any>;
	setListings: (listings: any) => void;
};

const ListingsContext = createContext<ListingsContextType>({
	listings: [],
	setListings: (listings) => console.log('HERE', listings),
});

export const ListingsProvider: FC<HeaderListingsProps> = ({ children }) => {
	const [listings, setListings] = useState<Array<any>>([]);

	return (
		<ListingsContext.Provider
			value={{ listings: listings, setListings: setListings }}
		>
			{children}
		</ListingsContext.Provider>
	);
};

export const useListings = () => {
	return useContext(ListingsContext);
};
