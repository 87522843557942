import React from 'react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export interface CommonLayoutProps {
	children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
	return (
		<div className='nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900'>
			<div className='container pt-14 sm:pt-20 pb-24 lg:pb-32'>{children}</div>
		</div>
	);
};

export default CommonLayout;
