import Page404 from 'containers/Page404/Page404';
import { Route, Routes } from 'react-router-dom';
import { pages } from 'routers';

export const AllRoutes = () => (
	<Routes>
		{pages.map(({ component, path }) => {
			const Component = component;
			return <Route key={path} element={<Component />} path={path} />;
		})}
		<Route element={<Page404 />} />
	</Routes>
);
