import { FC, FormEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AddListingFormSwitch from './AddListingFormSwitch';
import { useUser } from 'providers/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { getListingById } from 'utils/getListings';
import { checkLocation } from 'utils/checkLocation';

export interface AddListingProps {
	className?: string;
}

const AddListing: FC<AddListingProps> = ({ className = '' }) => {
	const { user } = useUser();

	const [formType, setFormType] = useState<number>(0);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [newListing, setNewListing] = useState<any>({});

	const { id } = useParams();

	const navigate = useNavigate();

	const onSubmitNextForm = (e: FormEvent, jsonValue: any) => {
		e.preventDefault();
		setNewListing((prevState: any) => ({ ...prevState, ...jsonValue }));
		if (formType < 4) {
			setFormType(formType + 1);
		}
	};

	const fetchListing = async () => {
		const _listing: any = await getListingById(id);

		if (_listing.userId !== user.id) {
			navigate('/panden');
			setIsLoading(false);
		} else {
			setNewListing(_listing);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			setIsLoading(true);
			fetchListing();
		}
	}, []);

	useEffect(() => {
		if (user) {
			setIsLoading(true);
			if (id) {
				setIsLoading(true);
				fetchListing();
			} else {
				setNewListing({ ...newListing, userId: user.id });
				setIsLoading(false);
			}
		} else {
			navigate('/login');
		}
	}, [user]);

	return (
		<div
			className={`nc-AddListing overflow-hidden ${className}`}
			data-nc-id='AddListing'
		>
			<Helmet>
				<title>
					{checkLocation('edit') ? 'Pand bewerken' : 'Pand toevoegen'} - Immo
					Mystique
				</title>
			</Helmet>
			<div className='mb-24 lg:mb-32'>
				<h2 className='my-18 sm:my-12 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
					{checkLocation('edit') ? 'Pand bewerken' : 'Nieuw pand toevoegen'}
				</h2>
				<div className='container mx-auto flex max-w-screen-md'>
					{!isLoading ? (
						<AddListingFormSwitch
							formType={formType}
							listing={newListing}
							setListing={onSubmitNextForm}
							setFormType={setFormType}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AddListing;
