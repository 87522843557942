import React, { useEffect, useState } from 'react';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import { FC } from 'react';

export interface GuestsObject {
	bedrooms?: number;
	bathrooms?: number;
	toilets?: number;
}
export interface GuestsInputProps {
	defaultValue: GuestsObject;
	onChange?: (data: GuestsObject) => void;
	className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
	defaultValue,
	onChange,
	className = '',
}) => {
	const [bedroomsInputValue, setBedroomsInputValue] = useState(
		defaultValue.bedrooms || 0
	);
	const [bathroomsInputValue, setBathroomsInputValue] = useState(
		defaultValue.bathrooms || 0
	);
	const [toiletsInputValue, setToiletsInputValue] = useState(
		defaultValue.toilets || 0
	);

	useEffect(() => {
		setBedroomsInputValue(defaultValue.bedrooms || 0);
	}, [defaultValue.bedrooms]);
	useEffect(() => {
		setBathroomsInputValue(defaultValue.bathrooms || 0);
	}, [defaultValue.bathrooms]);
	useEffect(() => {
		setToiletsInputValue(defaultValue.toilets || 0);
	}, [defaultValue.toilets]);

	const handleChangeData = (value: number, type: keyof GuestsObject) => {
		let newValue = {
			bedrooms: bedroomsInputValue,
			bathrooms: bathroomsInputValue,
			toilets: toiletsInputValue,
		};
		if (type === 'bedrooms') {
			setBedroomsInputValue(value);
			newValue.bedrooms = value;
		}
		if (type === 'bathrooms') {
			setBathroomsInputValue(value);
			newValue.bathrooms = value;
		}
		if (type === 'toilets') {
			setToiletsInputValue(value);
			newValue.toilets = value;
		}
		onChange && onChange(newValue);
	};

	return (
		<div className={`flex flex-col relative p-5 ${className}`}>
			<span className='mb-5 block font-semibold text-xl sm:text-2xl'>
				Who's coming?
			</span>
			<NcInputNumber
				className='w-full'
				defaultValue={bedroomsInputValue}
				onChange={(value) => handleChangeData(value, 'bedrooms')}
				max={20}
				label='Adults'
				desc='Ages 13 or above'
			/>
			<NcInputNumber
				className='w-full mt-6'
				defaultValue={bathroomsInputValue}
				onChange={(value) => handleChangeData(value, 'bathrooms')}
				max={20}
				label='Children'
				desc='Ages 2–12'
			/>

			<NcInputNumber
				className='w-full mt-6'
				defaultValue={toiletsInputValue}
				onChange={(value) => handleChangeData(value, 'toilets')}
				max={20}
				label='Infants'
				desc='Ages 0–2'
			/>
		</div>
	);
};

export default GuestsInput;
