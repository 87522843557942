const avatarColors = [
	'#ffdd00',
	'#fbb034',
	'#ff4c4c',
	'#c1d82f',
	'#f48924',
	'#7ac143',
	'#30c39e',
	'#06BCAE',
	'#0695BC',
	'#037ef3',
	'#146eb4',
	'#8e43e7',
	'#ea1d5d',
	'#fc636b',
	'#ff6319',
	'#e01f3d',
	'#a0ac48',
	'#00d1b2',
	'#472f92',
	'#388ed1',
	'#a6192e',
	'#4a8594',
	'#7B9FAB',
	'#1393BD',
	'#5E13BD',
	'#E208A7',
];

export { avatarColors };

export const monthNames = [
	'januari',
	'februari',
	'maart',
	'april',
	'mei',
	'juni',
	'juli',
	'augustus',
	'september',
	'oktober',
	'november',
	'december',
];

export const STATUS = {
	REMOVED: 'verwijderd',
	REVIEW: 'in review',
	REVIEWED: 'reviewed',
};

export const SEND_STATES = {
	SENDING: 'sending',
	NONE: 'none',
	ARRIVED: 'arrived',
};

interface TypeProps {
	name: string;
	value: string;
}

interface ListTypes {
	LAND: TypeProps;
	CITY: TypeProps;
	HALFOPEN: TypeProps;
	APPARTMENT: TypeProps;
}

export const LISTING_TYPES: ListTypes = {
	LAND: { name: 'Landelijk', value: 'landelijk' },
	CITY: { name: 'In de stad', value: 'inCity' },
	HALFOPEN: { name: 'Woning', value: 'halfOpen' },
	APPARTMENT: { name: 'Appartement', value: 'appartement' },
};
