import React, { FC } from 'react';
import rightImgPng from 'images/our-features.png';
import NcImage from 'shared/NcImage/NcImage';
import Badge from 'shared/Badge/Badge';

export interface SectionOurFeaturesProps {
	className?: string;
	rightImg?: string;
	type?: 'type1' | 'type2';
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
	className = 'lg:py-14',
	rightImg = rightImgPng,
	type = 'type1',
}) => {
	return (
		<div
			className={`nc-SectionOurFeatures relative flex flex-col items-center ${
				type === 'type1' ? 'lg:flex-row' : 'lg:flex-row-reverse'
			} ${className}`}
			data-nc-id='SectionOurFeatures'
		>
			<div className='flex-grow'>
				<NcImage src={rightImg} />
			</div>
			<div
				className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
					type === 'type1' ? 'lg:pl-16' : 'lg:pr-16'
				}`}
			>
				<span className='uppercase text-sm text-gray-400 tracking-widest'>
					VOORDELEN
				</span>
				<h2 className='font-semibold text-4xl mt-5'>
					Waarom kiezen voor Immo Mystique?
				</h2>

				<ul className='space-y-10 mt-16'>
					<li className='space-y-4'>
						<span className='block text-xl font-semibold'>Anoniem</span>
						<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
							Je pand wordt online geplaatst zonder exact adres of foto’s van de
							buitenkant. Voorbijgangers zullen dus ook niet zien dat jouw
							woning te koop staat.
						</span>
					</li>
					<li className='space-y-4'>
						<span className='block text-xl font-semibold'>Koopbaar</span>
						<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
							Door je pand op Immo Mystique te plaatsen, stel je het koopbaar.
							Je beslist alsnog zelf of je ingaat op een afspraak of niet.
							Potentiële kopers krijgen zo ook meer kansen om hun droomhuis te
							vinden.
						</span>
					</li>
					<li className='space-y-4'>
						<span className='block text-xl font-semibold'>Voordelig</span>
						<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
							Immo Mystique is gratis in gebruik. Maak je foto’s zelf, plaats je
							pand online en wacht tot wanneer een potentiële koper zich kenbaar
							maakt.
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SectionOurFeatures;
