import Heading from 'components/Heading/Heading';
import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import HIW1img from 'images/howWorks/OnlineIllustration.svg';
import HIW2img from 'images/howWorks/BuyIllustration.svg';
import HIW3img from 'images/howWorks/ProfIllustration.svg';
import VectorImg from 'images/VectorHIW.svg';

export interface SectionHowItWorkProps {
	className?: string;
	data?: {
		id: number;
		title: string;
		desc: string;
		img: string;
		imgDark?: string;
	}[];
}

const DEMO_DATA: SectionHowItWorkProps['data'] = [
	{
		id: 1,
		img: HIW1img,
		title: 'Online plaatsen',
		desc: 'Upload foto’s en gegevens van je woning. Stel een minimale prijs in.',
	},
	{
		id: 2,
		img: HIW2img,
		title: '(Ver)koper',
		desc: 'Wacht tot iemand zijn interesse uit. Kom in contact en bespreek de verdere stappen.',
	},
	{
		id: 3,
		img: HIW3img,
		title: 'Bemiddeling',
		desc: 'Dit laten we over aan professionals',
	},
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
	className = '',
	data = DEMO_DATA,
}) => {
	return (
		<div
			className={`nc-SectionHowItWork  ${className}`}
			data-nc-id='SectionHowItWork'
		>
			<Heading isCenter desc=''>
				Hoe werkt het?
			</Heading>
			<div className='mt-20 relative grid md:grid-cols-3 gap-20'>
				<img
					className='hidden md:block absolute inset-x-0 top-10'
					src={VectorImg}
					alt=''
				/>
				{data.map((item) => (
					<div
						key={item.id}
						className='relative flex flex-col items-center max-w-xs mx-auto'
					>
						{item.imgDark ? (
							<>
								<NcImage
									containerClassName='dark:hidden block mb-8 max-w-[200px] mx-auto'
									src={item.img}
								/>
								<NcImage
									containerClassName='hidden dark:block mb-8 max-w-[200px] mx-auto'
									src={item.imgDark}
								/>
							</>
						) : (
							<NcImage
								containerClassName='mb-8 max-w-[200px] mx-auto'
								src={item.img}
							/>
						)}
						<div className='text-center'>
							<h3 className='text-xl font-semibold'>{item.title}</h3>
							<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
								{item.desc}
							</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default SectionHowItWork;
