import { FC, FormEvent, useState } from 'react';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface AddListingForm4Props {
	className?: string;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
	setFormType: (nr: number) => void;
}

const AddListingForm4: FC<AddListingForm4Props> = ({
	className = '',
	listing,
	setListing,
	setFormType,
}) => {
	const [status, setStatus] = useState<string>(
		listing.generalInfo ? listing.generalInfo.state : 'afgewerkt'
	);

	const [basement, setBasement] = useState<boolean>(
		listing.generalInfo ? listing.generalInfo.basement : false
	);

	const [attic, setAttic] = useState<boolean>(
		listing.generalInfo ? listing.generalInfo.attic : false
	);

	const [centralHeat, setCentralHeat] = useState<boolean>(
		listing.generalInfo ? listing.generalInfo.centralHeat : false
	);

	const [garden, setGarden] = useState<boolean>(
		listing.generalInfo ? listing.generalInfo.garden : false
	);

	const [garage, setGarage] = useState<boolean>(
		listing.generalInfo ? listing.generalInfo.garage : false
	);

	const [orientationGarden, setOrientationGarden] = useState<string>(
		listing.generalInfo ? listing.generalInfo.orientationGarden : 'noord'
	);

	return (
		<>
			<form
				className='grid grid-cols-1 gap-6 w-full'
				onSubmit={(e) =>
					setListing(e, {
						generalInfo: {
							basement: basement,
							centralHeat: centralHeat,
							garage: garage,
							garden: garden,
							attic: attic,
							state: status,
							orientationGarden: orientationGarden,
						},
					})
				}
			>
				<div className='flex flex-col gap-4'>
					<h2>Welke extra's zijn aanwezig? </h2>
					<div className='flex flex-row gap-8'>
						<label className='flex gap-2 '>
							Kelder:
							<Input
								checked={basement}
								changeValue={() => setBasement(!basement)}
								className='w-3 h-3'
								type='checkbox'
							/>
						</label>
						<label className='flex gap-2 '>
							Zolder:
							<Input
								checked={attic}
								changeValue={() => setAttic(!attic)}
								className='w-3 h-3'
								type='checkbox'
							/>
						</label>
						<label className='flex gap-2 '>
							Centrale verwarming:
							<Input
								checked={centralHeat}
								changeValue={() => setCentralHeat(!centralHeat)}
								className='w-3 h-3'
								type='checkbox'
							/>
						</label>
						<label className='flex gap-2 '>
							Tuin:
							<Input
								checked={garden}
								changeValue={() => setGarden(!garden)}
								className='w-3 h-3'
								type='checkbox'
							/>
						</label>

						<label className='flex gap-2 '>
							Garage:
							<Input
								checked={garage}
								changeValue={() => setGarage(!garage)}
								className='w-3 h-3'
								type='checkbox'
							/>
						</label>
					</div>
					<div className='flex gap-6'>
						<label className='flex-1 gap-2'>
							<span>Status van het pand</span>
							<select
								value={status}
								onChange={(e) => setStatus(e.currentTarget.value)}
							>
								<option value='afgewerkt'>Afgewerkt</option>
								<option value='in verbouwing'>In verbouwing</option>
								<option value='op te doen'>Op te doen</option>
							</select>
						</label>

						{garden ? (
							<label className='flex-1 gap-2'>
								<span>Orientatie tuin</span>
								<select
									value={orientationGarden}
									onChange={(e) => setOrientationGarden(e.currentTarget.value)}
								>
									<option value='noord'>Noord</option>
									<option value='noord-oost'>Noord-Oost</option>
									<option value='oost'>Oost</option>
									<option value='oost-zuid'>Oost-Zuid</option>
									<option value='zuid'>Zuid</option>
									<option value='zuid-west'>Zuid-West</option>
									<option value='west'>West</option>
									<option value='noord-west'>Noord-West</option>
								</select>
							</label>
						) : null}
					</div>
				</div>
				<div className='flex justify-between'>
					<ButtonSecondary onClick={() => setFormType(2)}>
						Vorige stap
					</ButtonSecondary>

					<ButtonPrimary type='submit'>Volgende stap</ButtonPrimary>
				</div>
			</form>
		</>
	);
};

export default AddListingForm4;
