import { onAuthStateChanged } from 'firebase/auth';
import { useUser } from 'providers/UserProvider';
import { useEffect } from 'react';
import {
	BrowserRouter,
	useHref,
	useLocation,
	useParams,
} from 'react-router-dom';
import { useCookie } from 'react-use';
import MyRouter from 'routers/index';
import { auth } from 'utils/firestore';
import { getUser } from 'utils/getUser';
import { incrimentReferal } from 'utils/updateReferal';

function App() {
	const [refToken, setRefToken] = useCookie('referal');

	useEffect(() => {
		if (window.location.search.includes('ref=')) {
			setRefToken(window.location.search.split('ref=')[1].split('&')[0], {
				expires: 1,
			});
		}

		const root = document.querySelector('html');
		if (!root) return;
		!root.classList.contains('dark') && root.classList.add('dark');
		localStorage.theme = 'dark';
	}, []);

	const { user, setUser } = useUser();

	useEffect(() => {
		// signOut(auth);
		const checkLogin = async () => {
			await onAuthStateChanged(auth, async (usr) => {
				if (usr) {
					const _user = await getUser(usr.uid);

					setUser(_user);
				}
			});
		};
		checkLogin();
	}, []);

	return (
		<div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
			<BrowserRouter>
				<MyRouter />
			</BrowserRouter>
		</div>
	);
}

export default App;
