import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import { FormEvent, useState } from "react";
import { uid } from "uid";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { doc, setDoc } from "firebase/firestore";
import { DB } from "utils/firestore";
import SectionSubscribe3 from 'components/SectionSubscribe2/SectionSubscribe3';


const MakelaarsPage = () => {
  const [email, setEmail] = useState<string>("");

  const submitMakelaarsForm = async (e: FormEvent) => {
    e.preventDefault();
    const _makelaar = {
      id: uid(12),
      email: email,
    };
    setIsSending(true);
    await setDoc(doc(DB, "makelaars", _makelaar.id), { ..._makelaar })
      .then(() => {
        setEmail("");
        setIsDone(true);
        setIsSending(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSending(false);
      });
  };

  const [isSending, setIsSending] = useState(false);

  const [isDone, setIsDone] = useState(false);

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Partners
          </h1>
          <h3>Ontdek de kracht van samenwerking</h3>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Bij Immo Mystique geloven we in de meerwaarde van samenwerking met
            erkende BIV bemiddelaars. Onze missie is om unieke
            vastgoedervaringen te creëren, en daarbij is een sterke samenwerking
            met professionele partners essentieel.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
          <h3>Waarom samenwerken met Immo Mystique?</h3>
          
            <ul>
              <li>
                Uniek netwerk: als partner van Immo Mystique kan je fungeren als
                bemiddelaar tussen verkopers die hun vastgoed koopbaar willen
                stellen & potentiële kopers;
              </li>
              <li>
                Kosten die je anders maakt om vastgoed toe te voegen aan je
                portefeuille spaar je via ons uit;
              </li>
              <li>
                Eindelijk een medium om panden op anonieme wijze te plaatsen.
              </li>
            </ul>
          
        </div>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <h3>Hoe verloopt de samenwerking?</h3>
          
            <ul>
              <li>
                Word partner door contact met ons op te nemen en je interesse
                kenbaar te maken;
              </li>
              <li>
                Eens een akkoord tot samenwerking kan je als bemiddelaar worden
                aangesteld bij transacties.
              </li>
            </ul>
          
        </div>
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          <h3>Voorwaarde om samen te werken</h3>
          <p>
            Binnen Immo Mystique vinden we het heel belangrijk dat de verkoper
            het meeste rendement over houdt bij een transactie.
          </p>
          <p>
            We werken enkel samen met BIV bemiddelaars die het besparen van
            kosten gekoppeld aan inkoop van vastgoed ook in mindering brengen
            van hun uiteindelijke vergoeding naar de verkoper.{" "}
          </p>
          <p>
            BIV Bemiddelaars, ontdek de mystiek die vastgoed in zich heeft met
            Immo Mystique. Samen creëren we buitengewone vastgoedervaringen.
          </p>
        </div>
        <br></br><br></br>
        <div className='flex mb-6 justify-between gap-5 max-w-screen-md mx-auto flex-wrap md:flex-nowrap'>
							<div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
								<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
									20 euro
								</h3>
								<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
									Per maand gefactureerd*
								</span>
							</div>
              <div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
								<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
									58 euro
								</h3>
								<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
									Per kwartaal gefactureerd*
								</span>
							</div>
              <div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
								<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
									216 euro
								</h3>
								<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
									Per jaar gefactureerd*
								</span>
							</div>
              
						</div>
            <span>* Alle prijzen zijn excl. btw.</span>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16  ">
      <Helmet>
        <title>Partners</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10 max-w-screen-md mx-auto ">
        {renderContent()}
        <div className="flex w-full">
          <NcImage
            className="w-full rounded-xl object-none h-[300px] "
            containerClassName="container "
            src={"/forsale-house.png"}
          />
          <NcImage
            className=" w-full rounded-xl object-none h-[300px] "
            containerClassName="container  "
            src={"/ImmoMystique_assets/door-key.png"}
          />
        </div>
        
        {/* {renderTags()} */}

        {/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}

          
        
        <div className="max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12"></div>
        		
      </div>
      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>

      <SectionSubscribe3 />
  </div>
    </div>
  );
};

export default MakelaarsPage;
