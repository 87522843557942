import { FC, FormEvent, useState } from 'react';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { resizeFile } from 'utils/imgCompressing';
import { checkLocation } from 'utils/checkLocation';
import { storage } from 'utils/firestore';
import { deleteObject, ref } from 'firebase/storage';
import convertNumbThousand, {
	convertNumbThousandBack,
} from 'utils/convertNumbThousand';
import Select from 'shared/Select/Select';
import { LISTING_TYPES } from 'contains/contants';
import Checkbox from 'shared/Checkbox/Checkbox';
import CheckboxCustom from 'shared/Checkbox/CheckboxCutom';

export interface AddListingForm1Props {
	className?: string;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
}

const AddListingForm1: FC<AddListingForm1Props> = ({
	className = '',
	listing,
	setListing,
}) => {
	const [name, setName] = useState(listing.title ? listing.title : '');
	const [type, setType] = useState(
		listing.type
			? Array.isArray(listing.type)
				? [...listing.type]
				: [listing.type]
			: []
	);

	const [price, setPrice] = useState<number | undefined>(
		listing.price ? Number(listing.price) : undefined
	);
	const [description, setDescription] = useState(
		listing.about ? listing.about : ''
	);

	const [imgs, setImgs] = useState<Array<any>>(
		listing.photos ? listing.photos : []
	);

	const [err, setErr] = useState<string>('');

	const addNewImg = async (files: any) => {
		for (let ind = 0; ind < Array.from(files).length; ind++) {
			const file = Array.from(files)[ind];
			const _compressedImg = await resizeFile(file);
			setImgs((prevArr: any) => [...prevArr, _compressedImg]);
			setErr('');
		}
	};

	const removeImg = async (img: any) => {
		const newImgs = imgs.filter((_img) => _img !== img);
		setImgs(newImgs);
		if (checkLocation('edit')) {
			const imgRef = ref(storage, `listings/${listing.id}/${img}.png`);
			await deleteObject(imgRef)
				.then(() => {
					console.log('Deleted');
				})
				.catch((error) => {
					console.log(error);
				});
		}
		if (newImgs.length === 0) {
			setErr('Gelieve één of meerdere fotos toe te voegen');
		} else {
			setErr('');
		}
	};

	const submitForm = (e: FormEvent) => {
		e.preventDefault();

		if (imgs.length === 0) {
			setErr('Gelieve één of meerdere fotos toe te voegen');
		} else {
			setErr('');
			setListing(e, {
				title: name,
				about: description,
				photos: [...imgs],
				price: Number(price),
				type: type,
			});
			console.log('PRICE', Number(price));
		}
	};

	const updateType = (checked: boolean, value: string) => {
		console.log(checked, value);
		let _type = [...type];
		if (checked) {
			_type.push(value);
		} else {
			_type = type.filter((e: string) => e !== value);
		}
		console.log(_type);
		setType(_type);
	};

	return (
		<>
			<form
				className='grid grid-cols-2 gap-6 w-full'
				onSubmit={(e: FormEvent) => submitForm(e)}
			>
				<p className='text-red-500'>{err}</p>
				<div className='flex col-span-2 gap-3 flex-wrap'>
					{imgs.map((img) => (
						<div className='w-28 h-28 rounded overflow-hidden relative border border-neutral-200'>
							<div
								onClick={() => removeImg(img)}
								className='cursor-pointer w-5 h-5 rounded p-1 absolute right-1 top-1 flex bg-black/50 w-5 h-5'
							>
								<svg
									viewBox='0 0 63 62'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<line
										x1='5.35096'
										y1='4.10717'
										x2='59.4158'
										y2='58.172'
										stroke='white'
										stroke-width='10'
									/>
									<line
										y1='-5'
										x2='76.4593'
										y2='-5'
										transform='matrix(-0.707107 0.707107 0.707107 0.707107 61.4943 7.6427)'
										stroke='white'
										stroke-width='10'
									/>
								</svg>
							</div>
							<img
								className=' w-full object-cover h-full'
								src={
									typeof img === 'string'
										? `https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/listings%2F${listing.id}%2F${img}.png?alt=media`
										: URL.createObjectURL(img)
								}
							/>
						</div>
					))}
					<div className=' w-28 h-28 relative rounded overflow-hidden flex'>
						<div className='absolute inset-0 bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer'>
							<svg
								width='30'
								height='30'
								viewBox='0 0 30 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125'
									stroke='currentColor'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>

							<span className='mt-1 text-xs'>Foto toevoegen</span>
						</div>
						<input
							type='file'
							accept='image/png, image/jpeg'
							className='absolute inset-0 opacity-0 cursor-pointer'
							multiple
							onChange={(event) => {
								addNewImg(event.target.files);
							}}
						/>
					</div>
				</div>
				<div className='grid grid-cols-8 col-span-2 gap-4'>
					<label className='block col-span-5 '>
						<Label>Pand titel</Label>

						<Input
							required
							placeholder='Geef een titel aan je pand'
							type='text'
							className='mt-1'
							value={name}
							changeValue={setName}
						/>
					</label>

					<label className='block col-span-3'>
						<Label>Minimum prijs</Label>

						<Input
							pattern='[0-9]+([\.,][0-9]+([\.,][0-9]+)?'
							placeholder='Pand prijs'
							type='text'
							className='mt-1'
							value={convertNumbThousand(price)}
							changeValue={setPrice}
						/>
					</label>
				</div>

				<div className='block col-span-2 '>
					<Label>Type pand</Label>

					<div className=' flex flex-wrap gap-4'>
						<CheckboxCustom
							name={LISTING_TYPES.LAND.value}
							label={LISTING_TYPES.LAND.name}
							onChange={updateType}
							defaultChecked={type.includes(LISTING_TYPES.LAND.value)}
						/>

						<CheckboxCustom
							name={LISTING_TYPES.CITY.value}
							label={LISTING_TYPES.CITY.name}
							onChange={updateType}
							defaultChecked={type.includes(LISTING_TYPES.CITY.value)}
						/>

						<CheckboxCustom
							name={LISTING_TYPES.HALFOPEN.value}
							label={LISTING_TYPES.HALFOPEN.name}
							onChange={updateType}
							defaultChecked={type.includes(LISTING_TYPES.HALFOPEN.value)}
						/>

						<CheckboxCustom
							name={LISTING_TYPES.APPARTMENT.value}
							label={LISTING_TYPES.APPARTMENT.name}
							defaultChecked={type.includes(LISTING_TYPES.APPARTMENT.value)}
							onChange={updateType}
						/>
					</div>
				</div>

				<label className='block col-span-2 '>
					<Label>Beschrijving</Label>

					<Textarea
						onChange={(e) => setDescription(e.currentTarget.value)}
						value={description}
						required
						className='mt-1 resize-none'
						rows={6}
						placeholder='Vertel wat meer over je pand'
					/>
				</label>
				<div className=' col-span-2 w-full flex justify-between'>
					<div />

					<ButtonPrimary type='submit'>Volgende stap</ButtonPrimary>
				</div>
			</form>
		</>
	);
};

export default AddListingForm1;
