import { FC, FormEvent } from 'react';
import AddListingForm1 from './AddListingForm1';
import AddListingForm2 from './AddListingForm2';
import AddListingForm3 from './AddListingForm3';
import AddListingForm4 from './AddListingForm4';
import AddListingForm5 from './AddListingForm5';

export interface AddListingFormSwitchProps {
	formType?: number;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
	setFormType: (nr: number) => void;
}

const AddListingFormSwitch: FC<AddListingFormSwitchProps> = ({
	formType,
	listing,
	setListing,
	setFormType,
}) => {
	switch (formType) {
		case 1:
			return (
				<AddListingForm2
					setFormType={setFormType}
					listing={listing}
					setListing={setListing}
				/>
			);

		case 2:
			return (
				<AddListingForm3
					setFormType={setFormType}
					listing={listing}
					setListing={setListing}
				/>
			);

		case 3:
			return (
				<AddListingForm4
					setFormType={setFormType}
					listing={listing}
					setListing={setListing}
				/>
			);

		case 4:
			return (
				<AddListingForm5
					setFormType={setFormType}
					listing={listing}
					setListing={setListing}
				/>
			);

		default:
			return <AddListingForm1 listing={listing} setListing={setListing} />;
	}
};

export default AddListingFormSwitch;
