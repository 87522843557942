import { FC, useEffect, useState } from 'react';
import Heading from 'shared/Heading/Heading';
import Nav from 'shared/Nav/Nav';
import NavItem from 'shared/NavItem/NavItem';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export interface HeaderFilterProps {
	tabActive: number;
	tabs: any[];
	heading: ReactNode;
	subHeading?: ReactNode;
	onClickTab: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
	tabActive,
	tabs,
	subHeading = '',
	heading = '🎈 Latest Articles',
	onClickTab,
}) => {
	const [tabActiveState, setTabActiveState] = useState(tabs[tabActive]);

	const navigate = useNavigate();

	useEffect(() => {
		setTabActiveState(tabs[tabActive]);
	}, [tabActive]);

	const handleClickTab = (item: any) => {
		onClickTab && onClickTab(item);
		setTabActiveState(item);
	};

	return (
		<div className='flex flex-col mb-8 relative'>
			<Heading desc={subHeading}>{heading}</Heading>
			<div className='flex items-center justify-between'>
				<Nav
					className='sm:space-x-2'
					containerClassName='relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar'
				>
					{tabs.map((item, index) => (
						<NavItem
							key={index}
							isActive={tabActiveState === item}
							onClick={() => handleClickTab(item)}
						>
							{item.name}
						</NavItem>
					))}
				</Nav>
				<span className='hidden sm:block flex-shrink-0'>
					<ButtonSecondary
						className='!leading-none'
						onClick={() => navigate('/panden')}
					>
						<span>Bekijk alle panden</span>
						<i className='ml-3 las la-arrow-right text-xl'></i>
					</ButtonSecondary>
				</span>
			</div>
		</div>
	);
};

export default HeaderFilter;
