import React, { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import imagePng from 'images/hero-right.png';
import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm';
import { useUser } from 'providers/UserProvider';
import { useNavigate } from 'react-router-dom';

export interface SectionHeroProps {
	className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
	const navigate = useNavigate();

	return (
		<div
			className={`nc-SectionHero flex flex-col lg:flex-col relative ${className}`}
			data-nc-id='SectionHero'
		>
			<div className='flex flex-col lg:flex-row lg:items-center'>
				<div className='flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0'>
					<h2 className='font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] '>
						Selling houses in a mysterious way.
					</h2>
					<span className='text-base md:text-lg text-neutral-500 dark:text-neutral-400'>
						Plaats jouw vastgoed koopbaar in alle anonimiteit. Ontdek zelf
						hoeveel jouw pand waard is voor mogelijke kopers.
					</span>
					<ButtonPrimary onClick={() => navigate('/pand-toevoegen')}>
						Voeg je pand toe
					</ButtonPrimary>
				</div>
				<div className='flex-grow'>
					<img className='w-full' src={imagePng} alt='hero' />
				</div>
			</div>

			<div className=' block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full'>
				<HeroSearchForm />
			</div>
		</div>
	);
};

export default SectionHero;
