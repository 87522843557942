import React, { useEffect, useState } from 'react';
import LocationInput from './LocationInput';
import GuestsInput, { GuestsInputProps } from './GuestsInput';
import { FocusedInputShape } from 'react-dates';
import StayDatesRangeInput from './StayDatesRangeInput';
import moment from 'moment';
import { FC } from 'react';

export interface DateRage {
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
}

export type StaySearchFormFields = 'location' | 'guests' | 'dates';

export interface StaySearchFormProps {
	haveDefaultValue?: boolean;
	defaultFieldFocus?: StaySearchFormFields;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = 'Gent, Oost-Vlaanderen';
const defaultDateRange = {
	startDate: moment(),
	endDate: moment().add(4, 'days'),
};

const defaultGuestValue: GuestsInputProps['defaultValue'] = {
	toilets: 2,
	bathrooms: 2,
	bedrooms: 1,
};

const StaySearchForm: FC<StaySearchFormProps> = ({
	haveDefaultValue = false,
	defaultFieldFocus,
}) => {
	const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
		startDate: null,
		endDate: null,
	});
	const [locationInputValue, setLocationInputValue] = useState('');
	const [guestValue, setGuestValue] = useState({});

	const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
		null
	);

	const renderForm = () => {
		return (
			<form className='relative flex rounded-full border border-neutral-200 dark:border-neutral-700'>
				<LocationInput
					defaultValue={locationInputValue}
					onChange={(e) => setLocationInputValue(e)}
					onInputDone={() => setDateFocused('startDate')}
					className='flex-[1.5]'
					autoFocus={defaultFieldFocus === 'location'}
				/>
				<StayDatesRangeInput
					defaultValue={dateRangeValue}
					defaultFocus={dateFocused}
					onChange={(data) => {
						setDateRangeValue(data);
					}}
					className='flex-[2]'
				/>

				<GuestsInput
					defaultValue={guestValue}
					onChange={(data) => setGuestValue(data)}
					className='flex-[1.2]'
					autoFocus={defaultFieldFocus === 'guests'}
					submitLink='/panden'
				/>
			</form>
		);
	};

	return renderForm();
};

export default StaySearchForm;
