import emailjs from '@emailjs/browser';

export const sendMail = (
	template: string = 'bid_reviewed',
	content: any = {
		username: 'cieleke',
		listing_title: 'dfkqjs',
		bid_price: '450',
		to_email: 'ciel@storything.be',
		listing_id: 'dsdqdq',
	}
) => {
	if (String(process.env.REACT_APP_SEND_EMAILS) === 'true') {
		emailjs.send('service_f8tpl2d', template, content, 'T0SZNrtJgnG4fvdmk');
	}
};
