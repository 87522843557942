import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import { auth } from 'utils/firestore';

export const ForgotPass = () => {
	const [email, setEmail] = useState<string>('');

	const [isSend, setIsSend] = useState<boolean>(false);

	const [err, setErr] = useState<boolean>(false);

	const handleResetPassword = async (e: any) => {
		e.preventDefault();
		await sendPasswordResetEmail(auth, email)
			.then(() => {
				setErr(false);
				setIsSend(true);
			})
			.catch((e) => {
				console.log(e);
				setErr(true);
			});
	};

	return (
		<div className={`nc-PageLogin `} data-nc-id='PageLogin'>
			<div className='container mb-24 lg:mb-32'>
				<h2 className='mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
					Wachtwoord vergeten?
				</h2>
				<div className='max-w-md mx-auto space-y-6'>
					{/* FORM */}
					{isSend ? (
						<p className='text-xl font-bold text-center'>Check je mailbox</p>
					) : (
						<form
							className='grid grid-cols-1 gap-6'
							action='#'
							method='post'
							onSubmit={(e) => handleResetPassword(e)}
						>
							<label className='block'>
								<span className='text-neutral-800 dark:text-neutral-200'>
									Wat is het email van uw account?
								</span>
								<Input
									type='email'
									placeholder='example@example.com'
									className='mt-1'
									value={email}
									changeValue={setEmail}
								/>
								{err && (
									<span className='text-red-500'>
										Dit email adres is niet gelinkt met een account
									</span>
								)}
							</label>
							<ButtonPrimary type='submit'>Wachtwoord resetten</ButtonPrimary>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};
