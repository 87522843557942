import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { FC, useEffect, useRef } from 'react';
import SectionGridFilterCard from './SectionGridFilterCard';
import { Helmet } from 'react-helmet';
import { useFilter } from 'providers/FilterProvider';
import { getListingInBound } from 'utils/getListings';
import { useListings } from 'providers/ListingProvider';
import {
	calculateNortheastPosition,
	calculateSouthwestPosition,
} from 'utils/getBound';
import { DEMO_CATS_2, LOCATIONS } from 'containers/PageHome/PageHome';
import { useSearchParam } from 'react-use';
import { useSearchParams } from 'react-router-dom';

export interface PandenPageProps {
	className?: string;
}

const PandenPage: FC<PandenPageProps> = ({ className = '' }) => {
	const { filter } = useFilter();

	const { setListings } = useListings();

	useEffect(() => {
		const fetchListings = async () => {
			//50.863120, 4.332597
			const NE = await calculateNortheastPosition(
				filter.active ? filter.radius : 400,
				filter.location
			);

			const SW = await calculateSouthwestPosition(
				filter.active ? filter.radius : 400,
				filter.location
			);

			const _listings = await getListingInBound({
				southwest: SW,
				northeast: NE,
			});
			setListings(_listings);
		};
		fetchListings();
	}, [filter]);

	return (
		<div
			className={`nc-PandenPage relative overflow-hidden ${className}`}
			data-nc-id='PandenPage'
		>
			<Helmet>
				<title>Panden - Immo Mystique</title>
			</Helmet>
			<BgGlassmorphism />

			<div className='container relative overflow-hidden'>
				{/* SECTION HERO */}

				<SectionHeroArchivePage
					currentPage='Stays'
					currentTab='Stays'
					className='pt-10 pb-24 lg:pb-28 lg:pt-16 '
				/>

				{/* SECTION */}
				<SectionGridFilterCard className='pb-24 lg:pb-28' />

				{/* SECTION 1 */}
				<div className='relative py-16'>
					<BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' />
					<SectionSliderNewCategories
						categories={DEMO_CATS_2}
						categoryCardType='card4'
						itemPerRow={4}
						heading='Unieke panden'
						subHeading='Naar wat voor pand ben je op zoek?'
						sliderStyle='none'
						uniqueClassName='PageHome_s2'
					/>
				</div>

				{/* SECTION */}
				<SectionSubscribe2 className='py-24 lg:py-28' />
			</div>
		</div>
	);
};

export default PandenPage;
