import { Tab } from '@headlessui/react';
import { FC, Fragment, useEffect, useState } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import { useUser } from 'providers/UserProvider';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { signOut } from 'firebase/auth';
import { auth } from 'utils/firestore';
import { useNavigate } from 'react-router-dom';
import ProfileListingCard from './ProfileListingCard';
import { getBidedListingsForUser } from 'utils/getBids';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { getListingsByUserId } from 'utils/getListings';
import { monthNames } from 'contains/contants';
import { getImgUrl } from 'utils/getImgUrl';

export interface DashboardPageProps {
	className?: string;
}

const DashboardPage: FC<DashboardPageProps> = ({ className = '' }) => {
	const { user, setUser } = useUser();
	let [categories] = useState(['Panden', 'Biedingen']);
	const navigate = useNavigate();
	const [userListing, setUserListing] = useState<Array<any>>([]);
	const [bidedListing, setBidedListing] = useState<Array<any>>([]);
	useEffect(() => {
		if (user) {
			const fecthUserListings = async () => {
				const _listings = await getListingsByUserId(user.id);
				setUserListing(_listings);
				const _bidedListings = await getBidedListingsForUser(user.id);
				setBidedListing(_bidedListings);
			};

			fecthUserListings();
		} else {
			navigate('/login');
		}
	}, [user]);

	const signOutUser = () => {
		signOut(auth);
		setUser(undefined);
		navigate('/');
	};

	if (user) {
		return (
			<div
				className={`nc-DashboardPage ${className}`}
				data-nc-id='DashboardPage'
			>
				<main className='container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row'>
					<div className='block flex-grow mb-24 lg:mb-0'>
						<div className='lg:sticky lg:top-24'>
							<div className=' w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8'>
								<Avatar
									hasCheckedClass='w-6 h-6 -top-0.5 right-2'
									sizeClass='w-28 h-28'
									imgUrl={getImgUrl(user.img, user)}
								/>

								{/* ---- */}
								<div className='space-y-3 text-center flex flex-col items-center'>
									<h2 className='text-3xl font-semibold'>{user.name}</h2>
								</div>

								{/* ---- */}
								<div className='border-b border-neutral-200 dark:border-neutral-700 w-14'></div>

								{/* ---- */}
								<div className='space-y-4'>
									<div className='flex items-center space-x-4'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-6 w-6 text-neutral-400'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth={1.5}
												d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
											/>
										</svg>
										<span className='text-neutral-6000 dark:text-neutral-300'>
											Actief sinds{' '}
											{user.joined instanceof Date
												? monthNames[user.joined.getMonth()]
												: monthNames[user.joined.toDate().getMonth()]}{' '}
											{user.joined instanceof Date
												? user.joined.getFullYear()
												: user.joined.toDate().getFullYear()}
										</span>
									</div>
								</div>
								<ButtonSecondary
									onClick={() => navigate('/dashboard-settings')}
								>
									Account instellingen
								</ButtonSecondary>
								<ButtonPrimary onClick={() => signOutUser()}>
									Log uit
								</ButtonPrimary>
							</div>
						</div>
					</div>
					<div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0'>
						<div className='listingSection__wrap'>
							<div className='flex flex-row justify-between'>
								<div>
									<h2 className='text-2xl font-semibold'>Jouw content</h2>
									<span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
										Hier vind je je biedingen en je zelf geplaatste panden
									</span>
								</div>
								<ButtonPrimary
									className='mt-5 text-center'
									href='/pand-toevoegen'
								>
									Pand toevoegen
								</ButtonPrimary>
							</div>
							<div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

							<div>
								<Tab.Group>
									<Tab.List className='flex space-x-1 overflow-x-auto mb-4'>
										{categories.map((item) => (
											<Tab key={item} as={Fragment}>
												{({ selected }) => (
													<button
														className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
															selected
																? 'bg-secondary-900 text-secondary-50 '
																: 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
														} `}
													>
														{item} -{' '}
														{item === 'Panden'
															? userListing.length
															: bidedListing.length}
													</button>
												)}
											</Tab>
										))}
									</Tab.List>
									<Tab.Panels>
										{/* LISTINGS HERE */}
										<Tab.Panel className='flex flex-col gap-4'>
											{userListing.map((listing) => (
												<>
													<ProfileListingCard
														userListing={userListing}
														updateUserListings={setUserListing}
														listing={listing}
													/>
												</>
											))}
										</Tab.Panel>

										<Tab.Panel className='flex flex-col gap-4'>
											{bidedListing.map((listing) => (
												<ProfileListingCard isBid={true} listing={listing} />
											))}
										</Tab.Panel>
									</Tab.Panels>
								</Tab.Group>
							</div>
						</div>
						{/* {renderSection2()} */}
					</div>
				</main>
			</div>
		);
	} else {
		return <p>loading</p>;
	}
};

export default DashboardPage;
