import { FC } from 'react';
import { Helmet } from 'react-helmet';

const CookiePolicy: FC = () => {
	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Cookie Policy
					</h1>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<p>
						Dit privacybeleid ("Beleid") beschrijft hoe Immo Mystique ("Wij",
						"ons" of "onze"), gevestigd te Nelson Mandelaplein 2 8500 Kortrijk ,
						de informatie verzamelt, gebruikt en deelt van individuele
						gebruikers die onze website{' '}
						<a href={window.location.origin}>
							{window.location.origin.split('//')[1]}
						</a>{' '}
						bezoeken.
					</p>

					<p>
						Door onze Website te gebruiken, stemt u in met de verzameling en het
						gebruik van informatie in overeenstemming met dit beleid. Indien u
						niet akkoord gaat met enig deel van dit beleid, dan heeft u de optie
						om onze Website niet te gebruiken.
					</p>

					<div
						id='single-entry-content'
						className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
					>
						<h3>Informatieverzameling en Gebruik</h3>
						<p>
							Wij verzamelen informatie om uw ervaring op onze Website te
							verbeteren. De verzamelde gegevens omvatten informatie die uw
							browser stuurt telkens wanneer u onze Website bezoekt. Dit kan uw
							IP-adres, browsertype, browserversie, de pagina's van onze Website
							die u bezoekt, de tijd en datum van uw bezoek, de tijd die u op
							die pagina's doorbrengt en andere statistieken omvatten.
						</p>
					</div>

					<p>
						Cookies zijn bestanden met een kleine hoeveelheid data, die een
						anonieme unieke identificator kunnen bevatten. Cookies worden van
						een website naar uw browser gestuurd en opgeslagen op de harde
						schijf van uw computer.
					</p>

					<p>
						Wij gebruiken "cookies" om informatie te verzamelen en om onze
						Website te verbeteren. U heeft de mogelijkheid om uw browser zo in
						te stellen dat alle cookies worden geweigerd of om aan te geven
						wanneer een cookie wordt verzonden. Echter, als u geen cookies
						accepteert, is het mogelijk dat u niet alle delen van onze Website
						kunt gebruiken.
					</p>

					<div
						id='single-entry-content'
						className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
					>
						<h3>Data Processing</h3>
						<p>
							Alle persoonlijke gegevens die wij verzamelen, worden verwerkt en
							opgeslagen op beveiligde servers. De verwerking van deze gegevens
							wordt uitgevoerd door ons personeel of door directe medewerkers
							die verantwoordelijk zijn voor de bedrijfsvoering van de Website.
							De toegang tot deze gegevens is beperkt tot die personen die een
							zakelijke noodzaak hebben om ze te verwerken.
						</p>

						<h3>Beveiliging</h3>
						<p>
							De veiligheid van uw persoonlijke informatie is belangrijk voor
							ons, maar onthoud dat geen enkele methode van overdracht over het
							internet, of methode van elektronische opslag, 100% veilig is.
							Hoewel wij ernaar streven om commercieel aanvaardbare middelen te
							gebruiken om uw Persoonlijke Informatie te beschermen, kunnen wij
							de absolute veiligheid ervan niet garanderen.
						</p>

						<h3>Uw Rechten</h3>
						<p>
							U heeft het recht om uw persoonlijke informatie in te zien, te
							corrigeren, te verwijderen, of de verwerking ervan te beperken. U
							heeft ook het recht om bezwaar te maken tegen de verwerking van uw
							persoonsgegevens en het recht op gegevensoverdraagbaarheid. Als u
							een verzoek wilt indienen met betrekking tot uw persoonsgegevens,
							kunt u contact met ons opnemen via{' '}
							<a href='mailto:egon@immomystique.be'>egon@immomystique.be</a>.
						</p>

						<h3>Contactinformatie</h3>
						<p>
							Voor vragen over dit privacybeleid, kunt u contact met ons
							opnemen:
						</p>
						<div className=' flex flex-col gap-0'>
							<p>
								Naam: Egon Gevaert <br />
								Adres: Nelson Mandelaplein 2, 8500 Kortrijk
								<br />
								E-mail:{' '}
								<a href='mailto:egon@immomystique.be'>egon@immomystique.be</a>
							</p>
						</div>
					</div>

					<p>Dit privacybeleid is voor het laatst bijgewerkt op: 27/11/2023</p>
				</div>
			</>
		);
	};

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16 '>
			<Helmet>
				<title>Cookie Policy</title>
			</Helmet>
			{renderHeader()}

			<div className='nc-SingleContent container space-y-10 mt-3'>
				{renderContent()}
				{/* {renderTags()} */}
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
				{/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}
			</div>
		</div>
	);
};

export default CookiePolicy;
