import React, { FC, useState } from 'react';
import ExperiencesSearchForm from './ExperiencesSearchForm';
import StaySearchForm from './StaySearchForm';
import RentalCarSearchForm from './RentalCarSearchForm';
import FlightSearchForm from './FlightSearchForm';

export type SearchTab = String | 'Experiences' | 'Cars' | 'Flights';

export interface HeroSearchFormProps {
	className?: string;
	currentTab?: SearchTab;
	currentPage?: String | 'Experiences' | 'Cars' | 'Flights';
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = '' }) => {
	return (
		<div
			className={`nc-HeroSearchForm w-full m-auto max-w-6xl py-5 lg:py-0 ${className}`}
			data-nc-id='HeroSearchForm'
		>
			<StaySearchForm haveDefaultValue={false} />
		</div>
	);
};

export default HeroSearchForm;
