import SectionHero from 'components/SectionHero/SectionHero';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionOurFeatures from 'components/SectionOurFeatures/SectionOurFeatures';
import SectionGridFeaturePlaces from './SectionGridFeaturePlaces';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { TaxonomyType } from 'data/types';
import SectionClientSay from 'components/SectionClientSay/SectionClientSay';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { LISTING_TYPES } from 'contains/contants';

export const LOCATIONS: TaxonomyType[] = [
	{
		id: '1',
		href: '/panden',
		name: 'Brugge',
		taxonomy: 'category',
		latitude: 51.20892,
		longitude: 3.22424,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fbrugge.jpg?alt=media&token=5e803eca-ec8d-44f8-b085-709ff1a79438',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Kortrijk',
		taxonomy: 'category',
		latitude: 50.82803,
		longitude: 3.26487,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fkortrijk.jpg?alt=media&token=6593e184-2aa3-4780-b904-055867782467',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Gent',
		taxonomy: 'category',
		latitude: 51.05,
		longitude: 3.71667,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fgent.jpg?alt=media&token=727c86fe-f551-4b3a-8d09-107f3cd20407',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Antwerpen',
		taxonomy: 'category',
		latitude: 51.21989,
		longitude: 4.40346,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fantwerpen.jpg?alt=media&token=ce8c1c14-02ba-4807-a531-6fcc0b9fd797',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Brussel',
		taxonomy: 'category',
		latitude: 50.85045,
		longitude: 4.34878,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fbrussel.jpg?alt=media&token=550d0590-0d8f-4b54-95b6-4f80c7881858',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Hasselt',
		taxonomy: 'category',
		latitude: 50.93106,
		longitude: 5.33781,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fhasselt.jpg?alt=media&token=7eaca59c-c29a-4eb9-be63-b3513c614e86',
	},
	{
		id: '2',
		href: '/panden',
		name: 'Leuven',
		taxonomy: 'category',
		latitude: 50.87959,
		longitude: 4.70093,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/cities%2Fleuven.jpg?alt=media&token=c27bcb63-09a4-4f84-a3d0-afe27b229af3',
	},
];

export const DEMO_CATS_2: TaxonomyType[] = [
	{
		id: '1',
		href: `/panden?type=${LISTING_TYPES.LAND.value}&scrollTo=1`,
		name: 'Landelijk',
		taxonomy: 'category',
		count: 188288,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/buidling_kinds%2Fland.jpg?alt=media&token=93613ab8-8dc0-4ec7-9781-7b216a002df9',
	},
	{
		id: '222',
		href: `/panden?type=${LISTING_TYPES.CITY.value}&scrollTo=1`,
		name: 'In de stad',
		taxonomy: 'category',
		count: 188288,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/buidling_kinds%2Fcity.jpg?alt=media&token=0fddd90f-00ae-4700-8227-e248f86eb23d',
	},
	{
		id: '3',
		href: `/panden?type=${LISTING_TYPES.HALFOPEN.value}&scrollTo=1`,
		name: 'Woning',
		taxonomy: 'category',
		count: 188288,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/buidling_kinds%2FsemiOpen.jpg?alt=media&token=37d714c4-a30d-4ef4-a91d-d1cc38ab02e7',
	},
	{
		id: '4',
		href: `/panden?type=${LISTING_TYPES.APPARTMENT.value}&scrollTo=1`,
		name: 'Appartement',
		taxonomy: 'category',
		count: 188288,
		thumbnail:
			'https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/buidling_kinds%2Fappartment.jpg?alt=media&token=5f3a824d-c7db-45a2-9ec3-73c3dd2fe702',
	},
];

function PageHome() {
	return (
		<div className='nc-PageHome relative overflow-hidden'>
			{/* GLASSMOPHIN */}
			<BgGlassmorphism />

			<div className='container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28'>
				{/* SECTION HERO */}
				<SectionHero className='pt-10 lg:pt-16 lg:pb-16' />

				{/* SECTION 1 */}
				<SectionSliderNewCategories
					categories={LOCATIONS}
					uniqueClassName='PageHome_s1'
					isLocationFilter={true}
				/>

				{/* SECTION2 */}
				<SectionOurFeatures />

				{/* SECTION */}
				<div className='relative py-16'>
					<BackgroundSection />
					<SectionGridFeaturePlaces />
				</div>

				{/* SECTION */}
				<SectionHowItWork />

				{/* SECTION 1 */}
				<div className='relative py-16'>
					<BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' />
					<SectionSliderNewCategories
						categories={DEMO_CATS_2}
						categoryCardType='card4'
						itemPerRow={4}
						heading='Unieke panden'
						subHeading='Naar wat voor pand ben je op zoek?'
						sliderStyle='none'
						uniqueClassName='PageHome_s2'
					/>
				</div>

				{/* SECTION */}
				<SectionSubscribe2 isHome={true} />
				{/* 
		
				<SectionSliderNewCategories
					heading='Explore by types of houses'
					subHeading='Explore houses based on 10 types of houses'
					categoryCardType='card5'
					itemPerRow={5}
					uniqueClassName='PageHome_s3'
				/>

			
				<div className='relative py-16'>
					<BackgroundSection />
					<SectionClientSay uniqueClassName='PageHome_' />
				</div> */}
			</div>
		</div>
	);
}

export default PageHome;
