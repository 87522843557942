export const convertNumbThousand = (
	x?: number | Promise<number> | undefined
): string => {
	if (!x) {
		return '0';
	}
	console.log(
		x
			.toString()
			.replace(/\D/g, '')
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	);
	return x
		.toString()
		.replace(/\D/g, '')
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
export default convertNumbThousand;

export const convertNumbThousandBack = (
	x?: number | Promise<number> | undefined
): string => {
	if (!x) {
		return '0';
	}
	console.log(x.toString().replace(' ', ''));
	return x.toString().replace(' ', '');
};
