import Resizer from 'react-image-file-resizer';

import Compressor from 'compressorjs';

export const resizeFile = async (file) =>
	new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: 0.6,

			success(result) {
				console.log('res', result);
				return resolve(result);
			},
			error(err) {
				console.log(err.message);
				return reject(err);
			},
		});
	});

export const resizeProfilePic = (file) =>
	new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: 0.5,

			success(result) {
				console.log('res', result);
				return resolve(result);
			},
			error(err) {
				console.log(err.message);
				return reject(err);
			},
		});
	});
