import NcImage from 'shared/NcImage/NcImage';
import { Helmet } from 'react-helmet';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useState } from 'react';
import ModalPhotos from 'containers/ListingDetailPage/ModalPhotos';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Privacy Policy
					</h1>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<p>
						Het is het beleid van Immo Mystique om uw privacy te respecteren met
						betrekking tot alle informatie die we van u verzamelen op onze
						website, www.immomystique.be.
					</p>

					<p>
						We vragen alleen om persoonlijke informatie wanneer we die echt
						nodig hebben om u een dienst te kunnen verlenen. We verzamelen het
						met eerlijke en wettige middelen, met uw kennis en toestemming. We
						laten u ook weten waarom we het verzamelen en hoe het gebruikt zal
						worden.
					</p>

					<p>
						We bewaren de verzamelde informatie alleen zolang als nodig is om u
						de door u gevraagde dienst te verlenen. Welke gegevens we opslaan,
						beschermen we met commercieel aanvaardbare middelen om verlies en
						diefstal te voorkomen, evenals onbevoegde toegang, openbaarmaking,
						kopiëren, gebruik of wijziging.
					</p>

					<p>
						We delen geen persoonlijk identificeerbare informatie in het
						openbaar of met derden, behalve wanneer dit wettelijk verplicht is.
					</p>

					<p>
						Onze website kan linken naar externe sites die niet door ons worden
						beheerd. Houd er rekening mee dat wij geen controle hebben over de
						inhoud en de praktijken van deze sites en dat wij niet
						verantwoordelijk of aansprakelijkheid kunnen gesteld worden voor hun
						privacybeleid.
					</p>

					<p>
						Het staat u vrij om ons verzoek om uw persoonlijke informatie te
						weigeren, waaruit volgt dat wij u mogelijk niet op de door u
						gewenste diensten kunnen leveren.
					</p>

					<p>
						Uw voortgezette gebruik van onze website zal worden beschouwd als
						een aanvaarding van onze praktijken rond privacy en persoonlijke
						informatie. Als u vragen heeft over hoe wij omgaan met
						gebruikersgegevens en persoonlijke informatie, neem dan gerust
						contact met ons op.
					</p>

					<p>Dit beleid is van kracht vanaf 1 mei 2023 .</p>

					<p>
						Check ook de{' '}
						<Link to='/algemene-voorwaarden'>algemene voorwaarden</Link>
					</p>
				</div>
			</>
		);
	};

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16 '>
			<Helmet>
				<title>Privacy Policy</title>
			</Helmet>
			{renderHeader()}

			<div className='nc-SingleContent container space-y-10 mt-3'>
				{renderContent()}
				{/* {renderTags()} */}
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
				{/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}
			</div>
		</div>
	);
};

export default PrivacyPolicy;
