import React, { FC, ReactNode, useEffect, useState } from 'react';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import HeaderFilter from './HeaderFilter';
import StayCard from 'components/StayCard/StayCard';
import { useListings } from 'providers/ListingProvider';
import {
	getListingInBound,
	getListings,
	getLocationListings,
} from 'utils/getListings';
import {
	calculateNortheastPosition,
	calculateSouthwestPosition,
} from 'utils/getBound';
import { LOCATIONS } from './PageHome';
import { useNavigate } from 'react-router-dom';

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
	stayListings?: StayDataType[];
	gridClass?: string;
	heading?: ReactNode;
	subHeading?: ReactNode;
	headingIsCenter?: boolean;
	tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
	stayListings = DEMO_DATA,
	gridClass = '',
	heading = 'Unieke panden',
	subHeading = 'Woningen die nog niet te koop staan op de reguliere immomarkt.',
	headingIsCenter,
	tabs = LOCATIONS,
}) => {
	const [filterLocation, setFilterLocation] = useState<any>(tabs[0]);

	const [isLoading, setIsLoading] = useState(false);

	const [localListings, setLocalListing] = useState<Array<any>>([]);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchListings = async () => {
			setIsLoading(true);

			const NE = await calculateNortheastPosition(20, {
				latitude: filterLocation.latitude,
				longitude: filterLocation.longitude,
			});

			const SW = await calculateSouthwestPosition(20, {
				latitude: filterLocation.latitude,
				longitude: filterLocation.longitude,
			});

			const _listings = await getListingInBound({
				southwest: SW,
				northeast: NE,
			});
			setLocalListing(_listings);
			setIsLoading(false);
		};

		fetchListings();
	}, [filterLocation]);

	return (
		<div className='nc-SectionGridFeaturePlaces relative'>
			<HeaderFilter
				tabActive={0}
				subHeading={subHeading}
				tabs={tabs}
				heading={heading}
				onClickTab={setFilterLocation}
			/>
			<div
				className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
			>
				{isLoading ? (
					<p>Loading</p>
				) : localListings.length > 0 ? (
					localListings.map((stay) => <StayCard key={stay.id} data={stay} />)
				) : (
					<p>Geen panden gevonden in deze regio</p>
				)}
			</div>
			<div className='flex mt-16 justify-center items-center'>
				<ButtonPrimary onClick={() => navigate('/panden')}>
					Bekijk alle panden
				</ButtonPrimary>
			</div>
		</div>
	);
};

export default SectionGridFeaturePlaces;
