import { FC, FormEvent, useState } from 'react';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { ref, uploadBytes } from 'firebase/storage';
import { uid } from 'uid';
import { DB, storage } from 'utils/firestore';
import { useUser } from 'providers/UserProvider';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { checkLocation } from 'utils/checkLocation';
import { STATUS } from 'contains/contants';
import { sendMail } from 'utils/sendMail';
import Select from 'shared/Select/Select';

export interface AddListingForm5Props {
	className?: string;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
	setFormType: (nr: number) => void;
}

const AddListingForm5: FC<AddListingForm5Props> = ({
	className = '',
	listing,
	setListing,
	setFormType,
}) => {
	const { user } = useUser();

	const navigate = useNavigate();

	const [category, setCategory] = useState<string>(
		listing.EPC ? listing.EPC.category : 'A+'
	);

	const [epcValue, setEpcValue] = useState<number>(
		listing.EPC ? listing.EPC.value : 0
	);

	const [EPCRef, setEPCRef] = useState<string>(
		listing.EPC ? listing.EPC.ref : ''
	);

	const [isSending, setIsSending] = useState<boolean>(false);

	const submitNewListing = async (e: any, epcData: any) => {
		e.preventDefault();
		setIsSending(true);
		let listingId;
		if (!listing.id) {
			listingId = uid(16);
		} else {
			listingId = listing.id;
		}
		let listingPhotos = [];

		for (let ind = 0; ind < listing.photos.length; ind++) {
			const img = listing.photos[ind];
			if (typeof img !== 'string') {
				const imgName = `${listing.title}-${uid(3)}`;

				listingPhotos.push(imgName);

				const storageRef = ref(storage, `listings/${listingId}/${imgName}.png`);
				await uploadBytes(storageRef, img);
			} else {
				listingPhotos.push(img);
			}
		}

		const newListing = {
			...listing,
			photos: listingPhotos,
			id: listingId,
			userId: user.id,
			EPC: epcData,
			created: listing.created ? listing.created : new Date(),
			status: listing.status ? listing.status : STATUS.REVIEW,
			updated: new Date(),
		};

		const listingRef = doc(DB, 'listings', newListing.id);
		await setDoc(listingRef, { ...newListing });
		setIsSending(false);

		sendMail('admin', {
			subject: 'Een nieuw pand werd toegevoegd',
			listing_id: newListing.id,
			listing_title: newListing.title,
			message: 'Er is een nieuw pand toegevoegd en wacht op een review',
			to_email: 'info@immomystique.be',
		});

		navigate(`/dashboard`);
	};

	return (
		<>
			<form
				className='grid grid-cols-1 gap-6 w-full'
				onSubmit={(e) =>
					submitNewListing(e, {
						category: category,
						value: Number(epcValue),
						ref: EPCRef,
					})
				}
			>
				<div>
					<h2 className='text-lg'>EPC info van uw pand</h2>
				</div>

				<div className='flex gap-6'>
					<label className='block'>
						<Label>Categorie</Label>

						<Select
							required
							disabled={isSending}
							className='mt-1'
							value={category}
							onChange={(e) => setCategory(e.currentTarget.value)}
						>
							<option value='A+'>A+</option>
							<option value='A'>A</option>
							<option value='B'>B</option>
							<option value='C'>C</option>
							<option value='D'>D</option>
							<option value='E'>E</option>
							<option value='F'>F</option>
							<option value='G'>G</option>
							<option value='Niet gekend'>Niet gekend</option>
						</Select>
					</label>

					<label className='block'>
						<Label>EPC-Referentie</Label>

						<Input
							required
							disabled={isSending || category === 'Niet gekend'}
							placeholder='EPC-Referentie'
							type='text'
							className='mt-1'
							value={EPCRef}
							changeValue={setEPCRef}
						/>
					</label>

					<label className='block'>
						<Label>EPC waarde</Label>

						<Input
							required
							disabled={isSending || category === 'Niet gekend'}
							placeholder='kWh/m2'
							type='number'
							className='mt-1'
							value={epcValue}
							changeValue={setEpcValue}
						/>
					</label>
				</div>

				<div className='flex justify-between'>
					<ButtonSecondary disabled={isSending} onClick={() => setFormType(3)}>
						Vorige stap
					</ButtonSecondary>

					<ButtonPrimary disabled={isSending} loading={isSending} type='submit'>
						{checkLocation('edit') ? 'Bewerkigen bevestigen' : 'Pand toevoegen'}
					</ButtonPrimary>
				</div>
			</form>
		</>
	);
};

export default AddListingForm5;
