import React, { useEffect, useState } from 'react';
import LocationInput from './LocationInput';
import GuestsInput, { GuestsInputProps } from './GuestsInput';
import { FocusedInputShape } from 'react-dates';
import StayDatesRangeInput from './StayDatesRangeInput';
import moment from 'moment';
import { FC } from 'react';
import PriceRangeInput from './PriceRangeInput';
import { useFilter } from 'providers/FilterProvider';
import RadiusInput from './RadiusInput';
import ButtonSubmit from './ButtonSubmit';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { type } from 'os';

export interface DateRage {
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
	haveDefaultValue?: boolean;
}

const StaySearchForm: FC<StaySearchFormProps> = ({}) => {
	const { filter, setFilter } = useFilter();
	const [radius, setRadius] = useState<number>(
		filter.radius ? filter.radius : 25
	);
	const [locationInputValue, setLocationInputValue] = useState(
		filter.location
			? filter.location
			: {
					name: 'Brussel',
					latitude: 50.84673,
					longitude: 4.352502,
			  }
	);
	const [specs, setSpecs] = useState(filter.specs ? filter.specs : {});

	const resetFilter = () => {
		setLocationInputValue({
			name: 'Brussel',
			latitude: 50.84673,
			longitude: 4.352502,
		});

		setRadius(0);
		setFilter({
			radius: 0,
			active: false,
			location: {
				name: 'Brussel',
				latitude: 50.84673,
				longitude: 4.352502,
			},
		});
	};

	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const removeParamSearch = () => {
		setSearchParams();
		window.location.reload();
	};
	const renderForm = () => {
		return (
			<>
				<form
					onSubmit={(e) => e.preventDefault()}
					className='w-full rounded-[50px] relative mt-8 flex flex-wrap items-center md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 '
				>
					<LocationInput
						defaultValue={locationInputValue}
						onChange={setLocationInputValue}
						onInputDone={(e) => setLocationInputValue(e)}
						className='flex-[1.5] min-w-fit'
					/>
					<div className='flex flex-[2] select-none min-w-fit max-[630px]:mb-5 max-[630px]:mx-5 mb-0'>
						<RadiusInput
							onChange={(data) => setRadius(data)}
							fieldClassName='flex-[2] select-none'
							radius={radius}
						/>
						<div className='pr-2'>
							<ButtonSubmit
								data={{
									location: locationInputValue,
									radius: radius,
									specs: specs,
								}}
								href={'/panden'}
							/>
						</div>
					</div>

					{/* <GuestsInput
					defaultValue={specs}
					onChange={(data) => setSpecs(data)}
					className='flex-[1.2]'
					filterData={{
						location: locationInputValue,
						radius: radius,
						specs: specs,
					}}
				/> */}
				</form>
				{filter.active ? (
					<button
						onClick={() => resetFilter()}
						className='mt-3 ml-10 opacity-75'
					>
						Verwijder filter
					</button>
				) : searchParams.get('type') ? (
					<button
						onClick={() => removeParamSearch()}
						className='mt-3 ml-10 opacity-75'
					>
						Verwijder filter
					</button>
				) : null}
			</>
		);
	};

	return renderForm();
};

export default StaySearchForm;
