import { STATUS } from 'contains/contants';
import {
	collection,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import { DB } from './firestore';
import { getListingById } from './getListings';

export const getHighestUserBidForListing = async (listingId, userId) => {
	const docRef = collection(DB, `bids`);

	const bidsQuery = query(
		docRef,
		where('listingId', '==', listingId),
		where('biderId', '==', userId),
		orderBy('value', 'desc'),
		limit(1)
	);

	const bidsSnap = await getDocs(bidsQuery);
	let bids = [];

	if (!bidsSnap.empty) {
		await bidsSnap.forEach(async (bid) => {
			bids.push(bid.data());
		});
	}
	return bids[0];
};

export const getHighestBidForListing = async (listingId) => {
	const docRef = collection(DB, `bids`);

	const bidsQuery = query(
		docRef,
		where('listingId', '==', listingId),
		where('status', '==', STATUS.REVIEWED),
		orderBy('value', 'desc'),
		limit(1)
	);

	const bidsSnap = await getDocs(bidsQuery);
	let bids = [];

	if (!bidsSnap.empty) {
		await bidsSnap.forEach(async (bid) => {
			bids.push(bid.data());
		});
	}
	return bids[0];
};

export const getAllBidsForListing = async (listingId) => {
	const docRef = collection(DB, `bids`);

	const bidsQuery = query(
		docRef,
		where('listingId', '==', listingId),
		// where('status', '==', 'approved'),
		orderBy('value', 'desc')
	);

	const bidsSnap = await getDocs(bidsQuery);
	let bids = [];

	if (!bidsSnap.empty) {
		await bidsSnap.forEach(async (bid) => {
			bids.push(bid.data());
		});
	}
	return bids;
};

export const getBidedListingsForUser = async (userId) => {
	const docRef = collection(DB, 'bids');

	const bidsQuery = query(
		docRef,
		where('biderId', '==', userId),
		orderBy('created', 'desc'),
		limit(1)
	);

	const bidsRef = await getDocs(bidsQuery);
	let bids = [];
	bidsRef.forEach(async (group) => {
		bids.push(group.data());
	});
	const listings = [];

	for (let ind = 0; ind < bids.length; ind++) {
		const bid = bids[ind];
		const _listing = await getListingById(bid.listingId);
		listings.push(_listing);
	}

	return listings;
};

export const createNewBid = async (bid) => {
	const bidref = doc(DB, 'bids', bid.id);
	await setDoc(bidref, { ...bid });
	return bid;
};
