import rightImg from 'images/about-hero-right.png';
import { FC } from 'react';
import SectionFounder from './SectionFounder';
import { Helmet } from 'react-helmet';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionHero from './SectionHero';
import SectionClientSay from 'components/SectionClientSay/SectionClientSay';
import SectionStatistic from 'containers/PageAmbassadeurs/SectionStatistic';

export interface PageAboutProps {
	className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
	return (
		<div
			className={`nc-PageAbout overflow-hidden relative ${className}`}
			data-nc-id='PageAbout'
		>
			<Helmet>
				<title>Over Immo Mystique</title>
			</Helmet>

			{/* ======== BG GLASS ======== */}
			<BgGlassmorphism />

			<div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
				<SectionHero
					rightImg={rightImg}
					heading='👋 Over Immo Mystique'
					btnText=''
					subHeading=''
				/>
				{/* subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world." */}

				<SectionFounder />
				<div className='relative py-16'>
					<BackgroundSection />
					<SectionClientSay uniqueClassName='PageAbout_' />
				</div>

				<SectionStatistic />

				<SectionSubscribe2 />
			</div>
		</div>
	);
};

export default PageAbout;
