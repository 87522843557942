import React, { FC } from 'react';
import Avatar from 'shared/Avatar/Avatar';
import { PostDataType } from 'data/types';
import { Link } from 'react-router-dom';

export interface PostCardMetaProps {
	className?: string;
	meta: Pick<PostDataType, 'date' | 'author'>;
	hiddenAvatar?: boolean;
	size?: 'large' | 'normal';
}

const PostCardMeta: FC<PostCardMetaProps> = ({
	className = 'leading-none',
	meta,
	hiddenAvatar = false,
	size = 'normal',
}) => {
	const { date, author } = meta;
	return (
		<div
			className={`nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 ${
				size === 'normal' ? 'text-sm' : 'text-base'
			} ${className}`}
			data-nc-id='PostCardMeta'
		>
			<>
				<span className='text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1'>
					{date}
				</span>
			</>
		</div>
	);
};

export default PostCardMeta;
