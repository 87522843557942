import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FC } from 'react';
import Slider from 'rc-slider';
import convertNumbThousand from 'utils/convertNumbThousand';
import ButtonSubmit from './ButtonSubmit';

export interface RadiusInputProps {
	onChange: (data: any) => void;
	fieldClassName?: string;
	radius: number;
}

const RadiusInput: FC<RadiusInputProps> = ({
	onChange,
	fieldClassName = '[ nc-hero-field-padding ]',
	radius,
}) => {
	return (
		<Popover className='flex relative flex-[1.3]'>
			{({ open, close }) => (
				<>
					<div
						className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
							open ? 'nc-hero-field-focused' : ''
						}`}
					>
						<Popover.Button
							className={`flex-1 flex text-left items-center focus:outline-none ${fieldClassName} space-x-3 `}
							onClick={() => document.querySelector('html')?.click()}
						>
							<i className='text-neutral-300 dark:text-neutral-400 text-3xl las la-map-marked-alt'></i>

							<div className='flex-grow'>
								<span className='block xl:text-lg font-semibold truncate'>
									{`${radius}km`}
								</span>
								<span className='block mt-1 text-sm text-neutral-400 leading-none font-light '>
									De straal waarin u zoekt
								</span>
							</div>
						</Popover.Button>

						{/* BUTTON SUBMIT OF FORM */}
						{/* <div className="pr-2 xl:pr-4">
              <ButtonSubmit href="/listing-real-estate" />
            </div> */}
					</div>

					<Transition
						as={Fragment}
						enter='transition ease-out duration-200'
						enterFrom='opacity-0 translate-y-1'
						enterTo='opacity-100 translate-y-0'
						leave='transition ease-in duration-150'
						leaveFrom='opacity-100 translate-y-0'
						leaveTo='opacity-0 translate-y-1'
					>
						<Popover.Panel className='absolute left-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
							<div className='relative flex flex-col space-y-8'>
								<div className='space-y-5'>
									<span className='font-medium'>Radius van de locatie </span>
									<Slider
										className='text-red-400'
										min={5}
										max={50}
										defaultValue={radius}
										allowCross={false}
										step={1}
										onChange={(e) => onChange(e as number)}
									/>
								</div>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default RadiusInput;
