import CookieConsent, {
	Cookies,
	getCookieConsentValue,
} from 'react-cookie-consent';
import Input from 'shared/Input/Input';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { Link } from 'react-router-dom';

export const CookiesPopUp = () => {
	const [analitics, setAnalitics] = useState<boolean>(false);

	const [hasCookie, setHasCookie] = useState<boolean | undefined>();

	useEffect(() => {
		const _hasCookie =
			getCookieConsentValue('immo-mystique-consent') === 'true';

		console.log(_hasCookie);

		const _hasGACookie = Cookies.get('immo-mystique-consentGA') === 'true';

		setHasCookie(_hasCookie);

		if (_hasGACookie) {
			ReactGA.initialize('G-81RK084ZBJ');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, []);

	const acceptCookies = (isAll?: boolean) => {
		if (analitics) {
			Cookies.set('immo-mystique-consent', 'true', { expires: 365 });
			Cookies.set('immo-mystique-consentGA', 'true', { expires: 365 });
			ReactGA.initialize('G-81RK084ZBJ');
			ReactGA.pageview(window.location.pathname + window.location.search);
			setHasCookie(true);
		} else if (isAll) {
			Cookies.set('immo-mystique-consent', 'true', { expires: 365 });
			Cookies.set('immo-mystique-consentGA', 'true', { expires: 365 });
			ReactGA.initialize('G-81RK084ZBJ');
			ReactGA.pageview(window.location.pathname + window.location.search);
			setHasCookie(true);
		} else {
			Cookies.set('immo-mystique-consent', 'true', { expires: 365 });
			setHasCookie(true);
		}
	};
	return (
		<CookieConsent
			location='bottom'
			buttonText='Accpet cookies'
			cookieName='immo-mystique-consent'
			visible={hasCookie ? 'hidden' : 'show'}
			style={{
				background: '#1f2937bf',
				width: '100vw',
				padding: 10,
				display: 'flex',
				flexFlow: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			buttonStyle={{
				display: 'none',
			}}
			contentStyle={{
				display: 'flex',
				flexFlow: 'row',
				flexWrap: 'wrap',
				alignItems: 'center',
				gap: 5,
				flex: 1,
			}}
			expires={365}
		>
			<p className=' text-[16px] mr-2'>
				Deze site maakt gebruik van Cookies. Gebruik maken van deze website
				impliceert dat u hiermee akkoord gaat.{' '}
				<Link className=' underline ' to={'/cookie-policy'}>
					Cookie policy
				</Link>
			</p>
			<label className='flex content-center gap-2'>
				<Input className='w-3 h-3' type='checkbox' checked />
				<span>Functionele cookies</span>
			</label>
			<label className='flex content-center gap-2'>
				<Input
					changeValue={() => setAnalitics(!analitics)}
					checked={analitics}
					className='w-3 h-3'
					type='checkbox'
				/>
				<span>Analytische cookies</span>
			</label>
			<div className=' flex gap-2  w-full flex justify-end'>
				<ButtonSecondary onClick={() => acceptCookies()}>
					Bewaar Selectie
				</ButtonSecondary>
				<ButtonSecondary onClick={() => acceptCookies(true)}>
					Accepteer Cookies
				</ButtonSecondary>
			</div>
		</CookieConsent>
	);
};
