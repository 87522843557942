import React, { FC, useEffect, useRef, useState } from 'react';
import StayCard from 'components/StayCard/StayCard';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { StayDataType } from 'data/types';
import Pagination from 'shared/Pagination/Pagination';
import TabFilters from './TabFilters';
import Heading2 from 'components/Heading/Heading2';
import { useListings } from 'providers/ListingProvider';
import { LISTING_TYPES } from 'contains/contants';
import { useFilter } from 'providers/FilterProvider';
import { useSearchParams } from 'react-router-dom';

export interface SectionGridFilterCardProps {
	className?: string;
	data?: StayDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
	className = '',
}) => {
	const { listings } = useListings();

	let [searchParams, setSearchParams] = useSearchParams();

	const [typeSearch, setTypeSearch] = useState<string | undefined>(undefined);

	const scrollToRef = useRef<HTMLDivElement>(document.createElement('div'));

	useEffect(() => {
		const scrollTo = searchParams.get('scrollTo');
		console.log('PARAM', scrollTo);

		if (scrollTo === '1') {
			if (scrollToRef !== null) {
				scrollToRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		}
	}, [searchParams]);

	useEffect(() => {
		console.log('get', searchParams.get('type'));
		if (window.location.search && window.location.search.includes('type=')) {
			const searchType: string = window.location.search
				.split('type=')[1]
				.split('&')[0];

			for (const [key, value] of Object.entries(LISTING_TYPES)) {
				console.log(value.value === searchType);
				if (String(value.value) === String(searchType)) {
					setTypeSearch(String(searchType));
				}
			}
		}
	}, [listings, searchParams]);

	return (
		<div
			className={`nc-SectionGridFilterCard ${className}`}
			data-nc-id='SectionGridFilterCard'
			id='listings'
			ref={scrollToRef}
		>
			<Heading2 />

			{/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
			<div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
				{listings.map((listing) => {
					if (typeSearch) {
						if (Array.isArray(listing.type)) {
							if (listing.type.includes(typeSearch)) {
								return <StayCard key={listing.id} data={listing} />;
							}
						} else {
							if (listing.type === typeSearch) {
								return <StayCard key={listing.id} data={listing} />;
							}
						}
					} else {
						return <StayCard key={listing.id} data={listing} />;
					}
				})}
			</div>
			{/* <div className='flex mt-16 justify-center items-center'>
				<Pagination />
			</div> */}
		</div>
	);
};

export default SectionGridFilterCard;
