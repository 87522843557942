import React from 'react';
import NavigationItem from './NavigationItem';
import { NAVIGATION_DEMO } from 'data/navigation';
import NavigationItemPanden from './NavigationItemPanden';

function Navigation() {
	return (
		<ul className='nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative'>
			{NAVIGATION_DEMO.map((item) => {
				if (item.name === 'Panden') {
					return <NavigationItemPanden key={item.id} menuItem={item} />;
				} else {
					return <NavigationItem key={item.id} menuItem={item} />;
				}
			})}
		</ul>
	);
}

export default Navigation;
