import { FC, FormEvent, useEffect, useState } from 'react';
import facebookSvg from 'images/Facebook.svg';
import twitterSvg from 'images/Twitter.svg';
import googleSvg from 'images/Google.svg';
import Input from 'shared/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { auth } from 'utils/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getUser } from 'utils/getUser';
import { useUser } from 'providers/UserProvider';
import { ErrProps } from 'containers/PageSignUp/PageSignUp';

export interface PageLoginProps {
	className?: string;
}

const loginSocials = [
	{
		name: 'Continue with Facebook',
		href: '#',
		icon: facebookSvg,
	},
	{
		name: 'Continue with Twitter',
		href: '#',
		icon: twitterSvg,
	},
	{
		name: 'Continue with Google',
		href: '#',
		icon: googleSvg,
	},
];

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [err, setErr] = useState<ErrProps>({ type: 'none', err: 'none' });

	const { setUser, user } = useUser();

	const navigate = useNavigate();

	const logInUser = async (e: FormEvent) => {
		e.preventDefault();
		setIsLoading(true);
		await signInWithEmailAndPassword(auth, email, password)
			.then(async (userCredential) => {
				// Signed in
				const _user = await getUser(userCredential.user.uid);
				setUser(_user);
				navigate('/');
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error.code);
				switch (error.code) {
					case 'auth/wrong-password':
						setErr({ type: 'password', err: 'Fout wachtwoord' });
						break;

					case 'auth/user-not-found':
						setErr({ type: 'email', err: 'Email is niet in gebruik' });
						break;

					default:
						break;
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (user) {
			navigate('/dashboard');
		}
	}, [user]);

	return (
		<div className={`nc-PageLogin ${className}`} data-nc-id='PageLogin'>
			<div className='container mb-24 lg:mb-32'>
				<h2 className='mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
					Login
				</h2>
				<div className='max-w-md mx-auto space-y-6'>
					{/* <div className='grid gap-3'>
						{loginSocials.map((item, index) => (
							<a
								key={index}
								href={item.href}
								className='nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]'
							>
								<img
									className='flex-shrink-0'
									src={item.icon}
									alt={item.name}
								/>
								<h3 className='flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm'>
									{item.name}
								</h3>
							</a>
						))}
					</div>

					<div className='relative text-center'>
						<span className='relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900'>
							OR
						</span>
						<div className='absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800'></div>
					</div> */}
					{/* FORM */}
					<form
						className='grid grid-cols-1 gap-6'
						action='#'
						method='post'
						onSubmit={(e) => logInUser(e)}
					>
						<label className='block'>
							<span className='text-neutral-800 dark:text-neutral-200'>
								Email
							</span>
							<Input
								type='email'
								placeholder='example@example.com'
								className='mt-1'
								disabled={isLoading}
								required
								value={email}
								changeValue={setEmail}
							/>
							<p className='text-red-500'>{err.type === 'email' && err.err}</p>
						</label>
						<label className='block'>
							<span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
								Wachtwoord
								<Link to='/forgot-pass' className='text-sm'>
									Wachtwoord vergeten?
								</Link>
							</span>
							<Input
								type='password'
								className='mt-1'
								disabled={isLoading}
								required
								placeholder='wachtwoord'
								value={password}
								changeValue={setPassword}
							/>
							<p className='text-red-500'>
								{err.type === 'password' && err.err}
							</p>
						</label>
						<ButtonPrimary
							disabled={isLoading}
							loading={isLoading}
							type='submit'
						>
							Inloggen
						</ButtonPrimary>
					</form>

					{/* ==== */}
					<span className='block text-center text-neutral-700 dark:text-neutral-300'>
						Nieuwe gebruiker? {` `}
						<Link className='text-green-500' to='/signup'>
							Maak een account
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PageLogin;
