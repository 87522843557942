import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { PostDataType } from 'data/types';
import { Link } from 'react-router-dom';
import PostCardMeta from 'components/PostCardMeta/PostCardMeta';
import PostTypeFeaturedIcon from 'components/PostTypeFeaturedIcon/PostTypeFeaturedIcon';
import SocialsShare from 'shared/SocialsShare/SocialsShare';
import { DEMO_POSTS } from 'data/posts';

export interface Card12Props {
	className?: string;
	post?: PostDataType;
}

const Card12: FC<Card12Props> = ({
	className = 'h-full',
	post = DEMO_POSTS[0],
}) => {
	const { title, href, featuredImage, desc, postType, id } = post;

	return (
		<Link
			to={`/blog/${id}`}
			className={`nc-Card12 group relative flex flex-col ${className}`}
			data-nc-id='Card12'
		>
			<Link
				to={`/blog/${id}`}
				className='block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden'
			>
				<NcImage
					containerClassName='absolute inset-0'
					src={featuredImage}
					alt={title}
				/>
				<span>
					<PostTypeFeaturedIcon
						className='absolute bottom-2 left-2'
						postType={postType}
						wrapSize='w-8 h-8'
						iconSize='w-4 h-4'
					/>
				</span>
			</Link>

			<div className=' mt-8 pr-10 flex flex-col'>
				<h2
					className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
				>
					<Link to={`/blog/${id}`} className='line-clamp-2' title={title}>
						{title}
					</Link>
				</h2>
				<span className='hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400'>
					<span className='line-clamp-2'> {desc}</span>
				</span>
				<PostCardMeta className='mt-5' meta={post} />
			</div>
		</Link>
	);
};

export default Card12;
