export const getImgUrl = (img: any, user: any) => {
	if (typeof img === 'string') {
		if (img === './defaultImg.png') {
			return './defaultImg.png';
		} else {
			return `https://firebasestorage.googleapis.com/v0/b/immo-mystique.appspot.com/o/profilePics%2F${user.id}%2F${user.img}?alt=media`;
		}
	} else {
		return URL.createObjectURL(img);
	}
};
