import React, { useState } from 'react';
import { FC } from 'react';
import { useEffect } from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import { useRef } from 'react';
import { LOCATIONS } from 'containers/PageHome/PageHome';

export interface LocationInputProps {
	defaultValue: any;
	onChange: (value: any) => void;
	onInputDone?: (value: string) => void;
	placeHolder?: string;
	desc?: string;
	className?: string;
	autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
	defaultValue,
	autoFocus = false,
	onChange,
	onInputDone,
	placeHolder = 'Locatie',
	desc = 'Waar wil je een pand zoeken?',
	className = 'nc-flex-1.5',
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const [value, setValue] = useState(defaultValue);
	const [showPopover, setShowPopover] = useState(autoFocus);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		setShowPopover(autoFocus);
	}, [autoFocus]);

	useEffect(() => {
		if (eventClickOutsideDiv) {
			document.removeEventListener('click', eventClickOutsideDiv);
		}
		showPopover && document.addEventListener('click', eventClickOutsideDiv);
		return () => {
			document.removeEventListener('click', eventClickOutsideDiv);
		};
	}, [showPopover]);

	useEffect(() => {
		if (showPopover && inputRef.current) {
			inputRef.current.focus();
		}
	}, [showPopover]);

	const eventClickOutsideDiv = (event: MouseEvent) => {
		if (!containerRef.current) return;
		// CLICK IN_SIDE
		if (!showPopover || containerRef.current.contains(event.target as Node)) {
			return;
		}
		// CLICK OUT_SIDE
		setShowPopover(false);
	};

	const handleSelectLocation = (item: any) => {
		setValue(item);
		onInputDone && onInputDone(item);
		setShowPopover(false);
	};

	const handleSelectChange = (e: any) => {
		onChange(e);
	};

	const renderRecentSearches = () => {
		return (
			<>
				<h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100'>
					Bekijk in steden
				</h3>
				<div className='mt-2'>
					{LOCATIONS.map((item) => (
						<span
							onClick={() => handleSelectLocation(item)}
							key={item.name}
							className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'
						>
							<span className='block text-neutral-400'></span>
							<span className=' block font-medium text-neutral-700 dark:text-neutral-200'>
								{item.name}
							</span>
						</span>
					))}
				</div>
			</>
		);
	};

	const renderSearchValue = () => {
		return (
			<>
				{LOCATIONS.map((item) => {
					if (item.name.includes(value.name))
						return (
							<span
								onClick={() => handleSelectLocation(item)}
								key={item.name}
								className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'
							>
								<span className='block text-neutral-400'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='h-4 w-4 sm:h-6 sm:w-6'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={1.5}
											d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
										/>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={1.5}
											d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
										/>
									</svg>
								</span>
								<span className='block font-medium text-neutral-700 dark:text-neutral-200'>
									{item.name}
								</span>
							</span>
						);
				})}
			</>
		);
	};

	const setAdress = (e: any) => {
		onChange({
			name: e,
			latitude: 50.84673,
			longitude: 4.352502,
		});
	};

	const handleSelect = (e: any) => {
		geocodeByAddress(e)
			.then((results: any) => getLatLng(results[0]))
			.then((latLng: any) => {
				console.log(latLng);
				onChange({
					name: e,
					...latLng,
					latitude: latLng.lat,
					longitude: latLng.lng,
				});
			})
			.catch((error: any) => console.error('Error', error));
	};

	return (
		<div
			className={`relative flex gap-2 items-center p-5 ml-2 ${className}`}
			ref={containerRef}
		>
			<i className='text-neutral-300 dark:text-neutral-400 text-3xl las la-map-marker-alt'></i>

			<PlacesAutocomplete
				value={value.name}
				onChange={setAdress}
				onSelect={handleSelect}
				searchOptions={{ types: ['locality'] }}
			>
				{({
					getInputProps,
					suggestions,
					getSuggestionItemProps,
					loading,
				}: any) => (
					<div className='location-search-input w-full'>
						<input
							{...getInputProps({
								placeholder: 'Zoek het adres van je pand',
								className: 'location-search-input w-full',
							})}
							required
						/>
						{suggestions.length > 0 && (
							<div className=' z-40 bg-slate-900 p-3 pt-0 rounded-2xl absolute autocomplete-dropdown-container'>
								{loading && <div>Loading...</div>}
								{suggestions.map((suggestion: any) => {
									const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
										? {
												border: 'solid 1px #45D62C',
												padding: 8,
												cursor: 'pointer',
												borderRadius: 20,
												marginTop: 10,
												backgroundColor: '#101827',
												width: '100%',
										  }
										: {
												border: 'solid 1px #ffffff',
												padding: 8,
												cursor: 'pointer',
												borderRadius: 20,
												marginTop: 10,
												backgroundColor: '#101827',
												width: '100%',
										  };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				)}
			</PlacesAutocomplete>

			{/* <select
				className='w-full lg:w-3/5 text-lg font-semibold'
				onChange={(e) =>
					handleSelectChange(LOCATIONS[Number(e.currentTarget.value)])
				}
			>
				{LOCATIONS.map((loc, i) => (
					<option
						className='text-2xl'
						selected={value.name === loc.name}
						value={i}
					>
						{loc.name}
					</option>
				))}
			</select> */}
		</div>
	);
};

export default LocationInput;
