import { FC, createContext, useState, useContext, useEffect } from 'react';
import { ReactNode } from 'react';
import { useCookie } from 'react-use';
import { incrimentReferal } from 'utils/updateReferal';

export interface HeaderUserProps {
	children: ReactNode;
}

export type UserContextType = {
	user: any;
	setUser: (user: any) => void;
};

const UserContext = createContext<UserContextType>({
	user: undefined,
	setUser: (user) => console.log('HERE', user),
});

export const UserProvider: FC<HeaderUserProps> = ({ children }) => {
	const [user, setUser] = useState<any>(undefined);
	return (
		<UserContext.Provider value={{ user: user, setUser: setUser }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	return useContext(UserContext);
};
