import { DEMO_POSTS } from 'data/posts';
import { PostDataType } from 'data/types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import SocialsList from 'shared/SocialsList/SocialsList';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet';

const BlogSingle = () => {
	const { id } = useParams();

	const [blogPost, setBlogPosts] = useState<undefined | PostDataType>();

	useEffect(() => {
		const _post = DEMO_POSTS.filter((post) => post.id === id)[0];
		console.log('POST', _post);
		setBlogPosts(_post);
	}, []);

	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						{blogPost?.title}
					</h1>
					<span className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
						{blogPost?.desc}
					</span>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = (sections: any) => {
		return (
			<div
				id='single-entry-content'
				className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
			>
				{sections.map((section: any, index: number) => {
					if (index === sections.length - 1) {
						return <p>{section.content}</p>;
					} else {
						return (
							<>
								<h3>{section.title}</h3>
								<p>{section.content}</p>
							</>
						);
					}
				})}
			</div>
		);
	};

	const renderTags = () => {
		return (
			<div className='max-w-screen-md mx-auto flex flex-wrap'>
				<a
					className='nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2'
					href='##'
				>
					Garden
				</a>
				<a
					className='nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2'
					href='##'
				>
					Jewelry
				</a>
				<a
					className='nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2'
					href='##'
				>
					Tools
				</a>
			</div>
		);
	};

	const renderAuthor = () => {
		return (
			<div className='max-w-screen-md mx-auto '>
				<div className='nc-SingleAuthor flex'>
					<Avatar sizeClass='w-11 h-11 md:w-24 md:h-24' />
					<div className='flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1'>
						<span className='text-xs text-neutral-400 uppercase tracking-wider'>
							WRITEN BY
						</span>
						<h2 className='text-lg font-semibold text-neutral-900 dark:text-neutral-200'>
							<a href='/'>Fones Mimi</a>
						</h2>
						<span className='text-sm text-neutral-500 sm:text-base dark:text-neutral-300'>
							There’s no stopping the tech giant. Apple now opens its 100th
							store in China.There’s no stopping the tech giant.
							<a className='text-primary-6000 font-medium ml-1' href='/'>
								Readmore
							</a>
						</span>
					</div>
				</div>
			</div>
		);
	};

	const renderCommentForm = () => {
		return (
			<div className='max-w-screen-md mx-auto pt-5'>
				<h3 className='text-xl font-semibold text-neutral-800 dark:text-neutral-200'>
					Responses (14)
				</h3>
				<form className='nc-SingleCommentForm mt-5'>
					<Textarea />
					<div className='mt-2 space-x-3'>
						<ButtonPrimary>Submit</ButtonPrimary>
						<ButtonSecondary>Cancel</ButtonSecondary>
					</div>
				</form>
			</div>
		);
	};

	const renderCommentLists = () => {
		return (
			<div className='max-w-screen-md mx-auto'>
				<ul className='nc-SingleCommentLists space-y-5'>
					<li>
						<Comment />
						<ul className='pl-4 mt-5 space-y-5 md:pl-11'>
							<li>
								<Comment isSmall />
							</li>
						</ul>
					</li>
					<li>
						<Comment />
						<ul className='pl-4 mt-5 space-y-5 md:pl-11'>
							<li>
								<Comment isSmall />
							</li>
						</ul>
					</li>
				</ul>
			</div>
		);
	};

	const renderPostRelated = (post: PostDataType) => {
		return (
			<div
				key={post.id}
				className='relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group'
			>
				<Link to={post.href} />
				<NcImage
					className='object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300'
					src={post.featuredImage}
				/>
				<div>
					<div className='absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black'></div>
				</div>
				<div className='flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4'>
					<Badge name='Categories' />
					<h2 className='block text-lg font-semibold text-white '>
						<span className='line-clamp-2'>{post.title}</span>
					</h2>

					<div className='flex'>
						<span className='block text-neutral-200 hover:text-white font-medium truncate'>
							{post.author.displayName}
						</span>
						<span className='mx-1.5 font-medium'>·</span>
						<span className='font-normal truncate'>{post.date}</span>
					</div>
				</div>
				<Link to={post.href} />
			</div>
		);
	};

	if (blogPost) {
		return (
			<div className='nc-PageSingle pt-8 lg:pt-16 '>
				<Helmet>
					<title>{blogPost.title}</title>
				</Helmet>
				{renderHeader()}
				<NcImage
					className='w-full rounded-xl object-none h-[720px] '
					containerClassName='container my-10 sm:my-12 '
					src={blogPost.featuredImage}
				/>

				<div className='nc-SingleContent container space-y-10'>
					{renderContent(blogPost.sections)}
					{/* {renderTags()} */}
					<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-32'></div>
					{/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}
				</div>
			</div>
		);
	} else {
		return <p>Loading</p>;
	}
};

export default BlogSingle;
