import React, { useEffect, useState } from 'react';
import LocationInput from './LocationInput';
import GuestsInput, { GuestsInputProps } from './GuestsInput';
import ExperiencesDateSingleInput from './ExperiencesDateSingleInput';
import moment from 'moment';
import { FC } from 'react';

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = 'Tokyo, Jappan';
const defaultDate = moment();
const defaultGuestValue: GuestsInputProps['defaultValue'] = {
	toilets: 2,
	bathrooms: 2,
	bedrooms: 1,
};

export interface ExperiencesSearchFormProps {
	haveDefaultValue?: boolean;
}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = ({
	haveDefaultValue,
}) => {
	const [dateValue, setdateValue] = useState<moment.Moment | null>(null);
	const [locationInputValue, setLocationInputValue] = useState('');
	const [guestValue, setGuestValue] = useState({});

	const [dateFocused, setDateFocused] = useState<boolean>(false);
	//

	useEffect(() => {
		if (haveDefaultValue) {
			setdateValue(defaultDate);
			setLocationInputValue(defaultLocationValue);
			setGuestValue(defaultGuestValue);
		}
	}, []);

	//

	const renderForm = () => {
		return (
			<form className='w-full relative flex flex-row rounded-full border border-neutral-200 dark:border-neutral-700'>
				<LocationInput
					defaultValue={locationInputValue}
					onChange={(e) => setLocationInputValue(e)}
					onInputDone={() => setDateFocused(true)}
					className='flex-[1.5]'
				/>

				<ExperiencesDateSingleInput
					defaultValue={dateValue}
					onChange={(date) => setdateValue(date)}
					defaultFocus={dateFocused}
					onFocusChange={(focus: boolean) => {
						setDateFocused(focus);
					}}
					className='flex-1'
				/>

				<GuestsInput
					defaultValue={guestValue}
					onChange={(data) => setGuestValue(data)}
					className='flex-[1.5]'
					submitLink='/listing-experiences'
				/>
			</form>
		);
	};

	return renderForm();
};

export default ExperiencesSearchForm;
