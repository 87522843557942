import GallerySlider from 'components/GallerySlider/GallerySlider';
import { useUser } from 'providers/UserProvider';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import convertNumbThousand from 'utils/convertNumbThousand';
import {
	getHighestBidForListing,
	getHighestUserBidForListing,
} from 'utils/getBids';
import { DeletePopUp } from './DeletePopUp';
import { STATUS } from 'contains/contants';
export interface ProfileListingCardProps {
	listing: any;
	isBid?: boolean;
	updateUserListings?: (e: any) => void;
	userListing?: any;
}

const ProfileListingCard: FC<ProfileListingCardProps> = ({
	listing,
	isBid,
	userListing,
	updateUserListings,
}) => {
	const { user } = useUser();
	const navigate = useNavigate();
	const goToListing = (id: string) => {
		console.log(listing);
		if (listing.status !== STATUS.REVIEW) {
			navigate(`/panden/${id}`);
		}
		// navigate(`/panden/${id}`);
	};

	const [ownBid, setOwnBid] = useState<any>({});
	const [highestBid, setHighestBid] = useState<any>({});

	useEffect(() => {
		const fetchBids = async () => {
			const _userBid = await getHighestUserBidForListing(listing.id, user.id);
			setOwnBid(_userBid);

			const _highestBid = await getHighestBidForListing(listing.id);
			setHighestBid(_highestBid);
		};

		fetchBids();
	}, []);

	return (
		<div
			key={listing.id}
			className='flex flex-row dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl will-change-transform cursor-pointer flex-wrap md:flex-nowrap'
		>
			<div className='relative flex-shrink-0 w-full md:w-44 '>
				<GallerySlider
					ratioClass='aspect-w-6 aspect-h-5'
					galleryImgs={listing.photos}
					uniqueID={`StayCardH_${listing.id}`}
					listingId={listing.id}
				/>
				{/* <BtnLikeIcon isLiked={false} className='absolute right-3 top-3' /> */}
			</div>
			<div className='flex flex-col gap-8 w-full pr-2'>
				<div
					className='w-full pt-2 pl-4 flex-column gap-4 transition duration-150 ease-in-out hover:opacity-60'
					onClick={() => goToListing(listing.id)}
				>
					<h3 className='text-lg font-medium capitalize normal-case'>
						{listing.title}{' '}
						{!isBid && <span className='text-sm'>{listing.status}</span>}
					</h3>
					{isBid ? (
						<>
							<p className='dark:text-neutral-400'>
								Jou hoogste bod:{' '}
								<span className='dark:text-neutral-400'>
									€ {convertNumbThousand(ownBid.value)}
								</span>
							</p>
							<p className='dark:text-neutral-400'>
								Bod status: {ownBid.status}
							</p>
						</>
					) : (
						<p className='dark:text-neutral-400'>
							Hoogste bod:{' '}
							{highestBid ? (
								<span>€ {convertNumbThousand(highestBid.value)}</span>
							) : (
								' Nog geen biedingen'
							)}
						</p>
					)}
				</div>
				{!isBid && (
					<div className='self-end flex gap-2'>
						<ButtonSecondary
							onClick={() => navigate(`/pand-edit/${listing.id}`)}
							className='self-end'
						>
							Bewerk dit pand
						</ButtonSecondary>
						<DeletePopUp
							listing={listing}
							updateUserListings={updateUserListings}
							userListing={userListing}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfileListingCard;
