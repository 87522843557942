import { Helmet } from 'react-helmet';

const AlgemeneVoorwaarden = () => {
	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Algemene Voorwaarden
					</h1>
					<span className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
						<span className=' font-bold	 '>
							Algemene gebruiksvoorwaarden van de site: immomystique.be
						</span>{' '}
						<br />
						Immo Mystique bv (beheerder van het Immo Mystique platform)
						<br />
						Gebruiker (iedereen aanwezig op het platform, ongeacht of deze
						persoon een pand wil aanbieden of een bod plaatsen)
					</span>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Onze gebruiksvoorwaarden</h3>
					<p>
						De site Immo Mystique (hierna ‘het Immo Mystique platform') wordt
						beheerd door de besloten vennootschap Immo Mystique waarvan de
						maatschappelijke zetel is gevestigd te Nelson Mandelaplein 2, 8500
						Kortrijk en ingeschreven bij de kruispuntbank van Ondernemingen
						onder het nummer: 0799.066.303
					</p>

					<p>
						Het loutere feit van uw gebruik van het Immo Mystique platform
						verklaart dat u de volgende gebruiksvoorwaarden integraal en
						onherroepelijk aanvaardt.
					</p>

					<p>
						Immo Mystique behoudt zich het recht om deze voorwaarden op elk
						moment en zonder voorafgaande waarschuwing te wijzigen. Wij raden u
						ook aan om deze op regelmatige basis te raadplegen.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Publicatie van een pand</h3>
					<p>
						De gebruiker garandeert dat elk pand die hij op het Immo Mystique
						platform publiceert, beantwoordt aan de kwaliteitscriteria van het
						Immo Mystique platform. Hiermee wordt bedoeld:
					</p>
					<ul>
						<li>
							De beschrijving van het aangeboden pand moet correct en duidelijk
							zijn.
						</li>

						<li>
							De specificaties van het pand wordt ingegeven in de daarvoor
							voorziene velden.
						</li>

						<li>
							Elk misleidend of foutief pand kan op elk moment door Immo
							Mystique verwijderd worden.
						</li>

						<li>
							De foto's bestemd voor publicatie moeten een goede resolutie
							hebben (minimum 1920x1080 pixels). Immo Mystique heeft op elk
							moment de mogelijkheid om elke foto te verwijderen die onduidelijk
							is, een slechte resolutie heeft of waarop een buitengevel te zien
							is. Enkel binnenhuisfoto’s en foto’s van de tuin worden aanvaard.
						</li>
					</ul>

					<p>
						Immo Mystique bv behoudt zich het recht voor om technische
						wijzigingen aan te brengen aan de publicatie, specifiek aan de
						inhoud van het pand (bijvoorbeeld in het kader van een hergebruik).
						Dit kan onder meer nodig zijn om het pand toegankelijk te maken op
						verschillende kanalen, waaronder mobiele telefoons.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Toegangsverbod tot het platform</h3>
					<p>
						Immo Mystique behoudt zich het recht de toegang te verbieden tot het
						gehele platform of een gedeelte ervan. Mogelijke redenen hiervoor
						kunnen zijn: (niet limitatieve lijst)
					</p>

					<ul>
						<li>
							Personen die op het platform valse panden plaatsen. Dwz panden die
							reeds eerder geplaatst werden, panden waarvan hij/zijn geen
							eigenaar is,…
						</li>

						<li>
							Personen die op een bepaalde manier de reputatie van Immo Mystique
							bv, het platform zou schaden;
						</li>

						<li>
							Personen die inbreuk plegen op de intellectuele rechten van
							derden;
						</li>

						<li>
							Personen die het platform zouden aanwenden voor onwettige
							doeleinden;
						</li>
					</ul>

					<p>
						Immo Mystique behoudt zich eveneens het recht om diezelfde personen
						gerechtelijk te vervolgen.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Beschikbaarheid van het platform</h3>
					<p>
						Wij zetten ons in om het Immo Mystique platform 7 dagen op 7 en 24
						uur op 24 ter beschikking te stellen. Niettegenstaande behouden wij
						ons het recht voor om op elk moment en zonder voorafgaande
						waarschuwing, de toegang tot het platform te onderbreken om
						technische of andere redenen.
					</p>

					<p>
						Wij behouden ons het recht onze diensten te beëindigen, dit zonder
						dat we verantwoordelijk kunnen gesteld worden voor de mogelijke
						gevolgen hiervan voor u of voor derden.
					</p>

					<p>
						Immo Mystique bv zal als een goede huisvader erop toezien dat het
						platform en de bestanden kunnen worden gedownload, vrij zijn van
						bugs, virussen, en andere kwaadaardige of niet goedgekeurde
						software. Wij kunnen echter niet uitsluiten dat deze zaken toch
						aanwezig zijn. Immo Mystique bv kan in geen geval aansprakelijk
						worden gehouden voor elke schade in welke vorm dan ook die hieruit
						voortvloeit voor de gebruiker. Immo Mystique bv raadt de gebruiker
						steeds aan firewalls, een antivirusprogramma en andere noodzakelijke
						beveiligingsprogramma's op zijn toestel te installeren.
					</p>
				</div>

				{/*COPY ME */}
				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Links naar andere sites</h3>
					<p>
						In bepaalde onderdelen wordt verwezen via hyperlinks naar inhoud van
						derden of naar websites die beheerd worden door derden. Immo
						Mystique is niet verantwoordelijk voor de kwaliteit en correctheid
						van de inhoud of voor deze websites zelf. Wij distantiëren ons van
						deze websites of inhoud. De beheerders van deze websites zijn de
						enige verantwoordelijken voor het naleven van de wetgevingen en
						reglementeringen in verband met de producten en diensten die ze te
						koop aanbieden op hun website. Meer bepaald wat betreft de
						bescherming van de consument, verkoop op afstand, wetgeving met
						betrekking tot prijzen, bescherming persoonsgegevens, enz.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Rubriek panden</h3>
					<p>
						Wij doen al het mogelijke om op het Immo Mystique platform een
						actueel en betrouwbaar gamma vastgoedpanden ter beschikking te
						stellen die koopbaar zijn.
					</p>

					<p>
						Noch Immo Mystique bv, noch de fysieke of rechtspersoon die ons het
						pand heeft aangeboden, kan verantwoordelijk worden gesteld voor
						eventuele fouten in de inhoud van het pand of niet-beschikbaarheid
						van het pand op het moment dat u het pand bekijkt. Het pand vormt in
						geen geval een aanbieding in de juridische zin van het woord.
					</p>

					<p>
						De informatie is altijd van voorlopige aard en moet steeds worden
						bevestigd door de vastgoedmakelaar, de notaris, de promotor of de
						particulier die het pand heeft geplaatst.
					</p>

					<p>
						Immo Mystique bv kan in geen geval aansprakelijk worden gesteld voor
						de wettelijkheid, de juistheid of de inhoud van de panden die op het
						Immo Mystique platform zijn geplaatst. Noch voor de kwaliteit van de
						panden en/of de diensten die in deze advertenties worden
						gepubliceerd.
					</p>

					<p>
						Inzake de aankoopkosten, wordt u aangeraden uw vastgoedmakelaar of
						notaris te raadplegen. Uw notaris kan u een nauwkeurig overzicht
						bezorgen van de erelonen, diverse kosten en rechten voor de
						ondertekening van de compromis. Wat betreft uw lening, raden wij u
						aan contact op te nemen met uw financiële instelling.
					</p>

					<p>
						Indien u een pand plaatst op het Immo Mystique platform, verbindt u
						zich ertoe uitsluitend inhoud vrij te geven (afbeeldingen, foto's,
						...) die niet in strijd is met de moraal en goede zeden, noch met de
						rechten van derden, inclusief de intellectuele eigendomsrechten. Dit
						met inbegrip van maar niet beperkt tot auteursrechten en
						aanverwanten, merkrechten, patenten, tekeningen en modellen, de
						rechten op handelsnamen, de bescherming van gegevensdatabanken,
						alsook de rechten ter bescherming van bepaalde kennis.
					</p>

					<p>
						U vrijwaart Immo Mystique bv van elke eventuele rechtsvordering van
						derden met betrekking tot het pand dat u op het Immo Mystique
						platform hebt geplaatst.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>
						Verantwoordelijkheid betreffende de inhoud die door gebruikers op
						het platform wordt geplaatst.
					</h3>
					<p>
						Alle teksten, gegevens, media, boodschappen of elke andere vorm van
						inhoud die door een gebruiker op het platform wordt geplaatst, valt
						enkel onder diens eigen verantwoordelijkheid. Immo Mystique bv kan
						niet verantwoordelijk worden gesteld voor de inhoud die een
						gebruiker op het platform plaatst.
					</p>

					<p>De gebruiker:</p>
					<ul>
						<li>
							Verklaart, garandeert en aanvaardt dat Immo Mystique bv over het
							recht beschikt om alle inhoud (tekst, afbeeldingen, informatie)
							die de gebruiker op het platform plaatst te gebruiken en te
							publiceren om de gevraagde dienst(en) uit te voeren;
						</li>
						<li>
							stemt ermee in dat deze gegevens door Immo Mystique bv kunnen
							worden gebruikt om deze diensten te leveren;
						</li>
					</ul>

					<p>
						Als gebruiker van het Immo Mystique-platform, verbindt u zich ertoe
						geen inhoud te plaatsen die (niet-limitatieve lijst)
					</p>

					<ul>
						<li>een politieke mening uitdrukt</li>
						<li>racistisch is</li>
						<li>
							aanzet tot discriminatie, haat of geweld tegen een persoon, een
							groep, een gemeenschap of hun leden
						</li>
					</ul>

					<p>
						Door het eenvoudige gebruik van het Immo Mystique-platform verbindt
						u zich ertoe haar bestuurders, partners, werknemers en ambassadeurs
						te vrijwaren van alle aansprakelijkheidsclaims en andere
						rechtsvorderingen die derden tegen Immo Mystique bv instellen als
						gevolg van de door u geplaatste inhoud.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Account aanmaken en wachtwoord</h3>
					<p>
						U kan zich vrij inschrijven op het Immo Mystique platform, door het
						vervolledigen van een formulier met uw persoonlijke gegevens.
						Wanneer u uw e-mailadres achterlaat, gaat u ermee akkoord om via
						e-mail informatie te ontvangen en wij zullen u dan ook proactief
						informeren over voor u relevante onderwerpen. Deze e-mails zijn niet
						marketing gericht, maar zijn louter informatief.
					</p>

					<p>
						Uw inschrijving geeft u toegang tot onderstaande gratis diensten:
					</p>

					<ul>
						<li>het opslaan van uw zoekopdrachten;</li>

						<li>het opslaan van vastgoed;</li>

						<li>
							de mogelijkheid om te zien hoe vaak uw pand werd bekeken sinds het
							werd online geplaatst;
						</li>

						<li>
							een waarschuwing via e-mail zodra er een niet bindend bod werd
							uitgebracht op uw pand;
						</li>

						<li>
							nieuwsbrieven en elektronische of papieren communicaties met
							informatie;
						</li>

						<li>
							telefoonnummer en e-mailadres doorgeven aan onze vaste
							makelaarspartner eens er een niet-bindend bod geplaatst is dat
							boven de opgegeven minimumprijs ligt.
						</li>
					</ul>

					<p>
						Uw wachtwoord is persoonlijk en vertrouwelijk. U bent de enige
						verantwoordelijke voor het gebruik ervan en verbindt zich ertoe dit
						wachtwoord niet door te geven aan derden.
					</p>

					<p>
						De gebruiker is de volledige en enige verantwoordelijke voor elke
						activiteit die wordt uitgevoerd onder zijn inlog.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Intellectuele rechten</h3>
					<p>
						De volledige inhoud van het Immo Mystique platform, met inbegrip van
						de teksten, foto’s, grafieken, logo's, code, database,… is de
						intellectuele eigendom van de besloten vennootschap Immo Mystique en
						is beschermd door de rechten op intellectuele eigendom. Hierin ook
						begrepen auteursrechten en de rechten van de producent van een
						database.
					</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Privacybeleid en gegevensbescherming</h3>
					<p>Via deze link vind je meer informatie over ons privacybeleid.</p>
				</div>

				<div
					id='single-entry-content'
					className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
				>
					<h3>Toepasselijke wetgeving en rechtspraak</h3>
					<p>
						Voor elk eventueel geschil dat voortvloeit uit het gebruik van het
						platform is de Belgische wet van toepassing, en zijn uitsluitend de
						Vlaamse rechtbanken bevoegd.
					</p>
					<p>
						Particulieren die een vraag en/of klacht hebben met betrekking tot
						de diensten van Immo Mystique kunnen contact opnemen met{' '}
						<a
							href='mailto:info@immomystique.be
'
						>
							onze Support
						</a>
						.
					</p>
				</div>
			</>
		);
	};

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16 '>
			<Helmet>
				<title>Algemene Voorwaarden</title>
			</Helmet>
			{renderHeader()}

			<div className='nc-SingleContent container space-y-10 mt-3'>
				{renderContent()}
				{/* {renderTags()} */}
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
			</div>
		</div>
	);
};

export default AlgemeneVoorwaarden;
