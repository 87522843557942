import { FC, FormEvent, useState } from 'react';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonThird from 'shared/Button/ButtonThird';

export interface AddListingForm3Props {
	className?: string;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
	setFormType: (nr: number) => void;
}

const AddListingForm3: FC<AddListingForm3Props> = ({
	className = '',
	listing,
	setListing,
	setFormType,
}) => {
	const [size, setSize] = useState<number>(listing.size ? listing.size : null);
	const [livingspace, setLivingspace] = useState<number>(
		listing.livingspace ? listing.livingspace : null
	);
	const [bedrooms, setBedrooms] = useState<number>(
		listing.bedrooms ? listing.bedrooms : null
	);

	const [rooms, setRooms] = useState<Array<any>>(
		listing.rooms ? listing.rooms : [{ name: null, size: null }]
	);

	const addNewRoom = () => {
		setRooms([...rooms, { name: null, size: null }]);
	};

	const updateRoom = (data: any, ind: number) => {
		let _rooms = [...rooms];
		_rooms[ind] = data;

		setRooms(_rooms);
	};

	const removeRoom = (removeRoom: any) => {
		const rmvdRooms = rooms.filter((room) => room !== removeRoom);
		setRooms(rmvdRooms);
	};

	return (
		<>
			<form
				className='grid grid-cols-1 gap-6 w-full'
				onSubmit={(e) =>
					setListing(e, {
						size: Number(size),
						livingspace: Number(livingspace),
						bedrooms: Number(bedrooms),
						rooms: rooms,
					})
				}
			>
				<div className='flex gap-6'>
					<label className='block'>
						<Label>Grond oppervlakte in m²</Label>

						<Input
							required
							placeholder='m²'
							type='number'
							className='mt-1'
							value={size}
							changeValue={setSize}
						/>
					</label>

					<label className='block'>
						<Label>Huis oppervlakte in m²</Label>

						<Input
							required
							placeholder='m²'
							type='number'
							className='mt-1'
							value={livingspace}
							changeValue={setLivingspace}
						/>
					</label>

					<label className='block'>
						<Label>Aantal slaapkamers</Label>

						<Input
							required
							placeholder='x slaapkamers'
							type='number'
							className='mt-1'
							value={bedrooms}
							changeValue={setBedrooms}
						/>
					</label>
				</div>
				<div className='flex flex-col gap-4'>
					<h2>Grootte van andere ruimtes </h2>
					<div className='flex flex-col gap-2'>
						{rooms.map((room, index) => (
							<div className='flex gap-x-5 items-center'>
								<label className='block'>
									<Label>Naam ruimte</Label>

									<Input
										required
										placeholder='Living, badkamer, ...'
										type='text'
										className='mt-1'
										value={room.name}
										custom
										changeValue={(e: any) =>
											updateRoom(
												{ name: e.currentTarget.value, size: room.size },
												index
											)
										}
									/>
								</label>

								<label className='block'>
									<Label>Kamer oppervlakte</Label>

									<Input
										required
										placeholder='m²'
										type='number'
										className='mt-1'
										value={room.size}
										custom
										changeValue={(e: any) =>
											updateRoom(
												{
													name: room.name,
													size: Number(e.currentTarget.value),
												},
												index
											)
										}
									/>
								</label>

								<ButtonCircle
									type='button'
									className='mt-6'
									onClick={() => removeRoom(room)}
								>
									<i className='la la-times'></i>
								</ButtonCircle>
							</div>
						))}
					</div>
					<div className='w-full flex justify-center'>
						<ButtonThird type='button' onClick={() => addNewRoom()}>
							Ruimte toevoegen
						</ButtonThird>
					</div>
				</div>
				<div className='flex justify-between'>
					<ButtonSecondary onClick={() => setFormType(1)}>
						Vorige stap
					</ButtonSecondary>

					<ButtonPrimary type='submit'>Volgende stap</ButtonPrimary>
				</div>
			</form>
		</>
	);
};

export default AddListingForm3;
