import Heading from 'components/Heading/Heading';
import Helmet from 'utils/helmetImport';
import { FAQItem } from './FAQItem';

const FAQs = [
	{
		title: 'Zijn er kosten verbonden aan Immo Mystique? ',
		text: 'Nee: Voor zowel potentiële kopers als verkopers zijn de handelingen binnen het platform gratis.',
	},
	{
		title: 'Is het bod dat ik online binnen het platform uitbreng bindend?',
		text: 'Nee: Op basis van de geplaatste foto’s en de praktische info dat je voor handen hebt, geef je aan dat er een koopintentie is en het richtbod(prijs) dat je in gedachten hebt.  De potentiële verkoper zal eerst gecontacteerd worden door een erkend BIV makelaar en de melding overbrengen dat er interesse is. Vanaf dat ogenblik is het aan de potentiële verkoper om te beslissen of hij wil ingaan op een ontmoeting. Bij een ontmoeting zal een BIV makelaar instaan voor de bemiddeling. Pas na een plaats bezoek kan je een officieel bindend bod uitbrengen.',
	},

	{
		title: 'Staat Immo Mystique in voor de bemiddeling?',
		text: '***Immo Mystique is geen vastgoedagentschap*** Wij bieden potentiële verkopers een platform aan voor het plaatsen van foto’s en details over hun pand dat ze koopbaar willen stellen.',
	},

	{
		title: 'Ik ben mijn wachtwoord vergeten.',
		text: 'Geen probleem: klik op de groene knop inloggen en vervolgens op de woorden “wachtwoord vergeten”. Je bent terug in de mogelijkheid om een nieuw wachtwoord in te stellen.',
	},
	{
		title: 'Hoe bewaren jullie de anonimiteit binnen het platform?',
		text: 'Voeg je een pand toe dan zal het pas publiek worden na een screening.',
	},
	{
		title: 'Ziet de buitenwereld de minimum prijs dat ik heb ingesteld?',
		text: 'Nee: Enkel de beheerders van Immo Mystique zien je ingestelde minimum prijs. Een BIV makelaar zal enkel actie ondernemen indien het niet bindend bod van de potentiële koper hoger ligt dan je ingestelde minimum prijs.',
	},
	{
		title: 'Kan ik wijzigingen aanbrengen aan mijn geplaatst pand?',
		text: 'Uiteraard ben je steeds in de mogelijkheid om aanpassingen door te voeren.',
	},
	{
		title: 'Ben ik verplicht om mijn woning te verkopen?',
		text: 'Nee: Verken potentiële interesse in je pand, maar je beslist zelf wanneer je effectief de beslissing neemt om te verkopen. Immo Mystique geeft je de opportuniteit om in alle rust de markt te verkennen.',
	},
	{
		title: 'Ik wil ambassadeur worden.',
		text: 'Fijn dat je onze missie wil uitdragen. Voor het ontvangen van een vergoeding is het vereist om een ondernemingsnummer te hebben.',
	},
	{
		title: 'Met welke BIV makelaars werken jullie samen?',
		text: 'Met Immo Mystique zijn we ons bewust dat we vastgoed op een andere manier benaderen dan dat we de dag van vandaag kennen. De bemiddeling laten we volledig over aan professionals. Voor BIV makelaars besparen we in commerciële kosten en tijd om nieuw vastgoed in hun portefeuille op te nemen. Neem contact op bij interesse om samen te werken.',
	},
	{
		title: 'Kan ik bouwgrond ook op jullie platform aanbieden?',
		text: 'Op heden beperken we ons tot appartementen & woningen.',
	},
];

export const FAQ = () => {
	return (
		<>
			<Helmet>
				<title>FAQ - Immo Mystique</title>
			</Helmet>
			<div className='flex justify-center my-20 w-full'>
				<div className=' m-auto flex flex-col items-center gap-10 w-4/5 max-w-[1080px]'>
					<h1 className='text-center text-5xl font-semibold flex items-center'>
						FAQ
					</h1>
					<div className=' flex-col flex gap-8 max-w-[1080px] w-full'>
						{FAQs.map((faq) => (
							<FAQItem title={faq.title} text={faq.text} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};
