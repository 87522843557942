import { STATUS } from 'contains/contants';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { updateListingStatus } from 'utils/getListings';

export const DeletePopUp = ({
	listing,
	userListing,
	updateUserListings,
}: any) => {
	const [isOpen, setIsOpen] = useState(false);

	const confirmDeleteListing = async () => {
		await updateListingStatus(listing.id, STATUS.REMOVED);
		const _listings = userListing.filter((list: any) => list.id !== listing.id);
		updateUserListings([..._listings]);
		setIsOpen(false);
	};

	return (
		<>
			<ButtonPrimary onClick={() => setIsOpen(true)}>
				<i className='las la-trash text-xl'></i>
			</ButtonPrimary>
			<Popup
				modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				closeOnDocumentClick={true}
				position='right center'
				contentStyle={{
					padding: 0,
					border: 'none',
					color: 'white',
					width: 'max-content',
				}}
			>
				<div className='bg-neutral-900 p-10 flex-col flex gap-6'>
					<p className='w-max'>
						Bent u zeker dat u{' '}
						<span className='font-bold'>{listing.title}</span> wil verwijderen?
					</p>
					<div className='flex justify-between'>
						<ButtonSecondary onClick={() => setIsOpen(false)}>
							Annuleren
						</ButtonSecondary>
						<ButtonPrimary onClick={() => confirmDeleteListing()}>
							Verwijderen
						</ButtonPrimary>
					</div>
				</div>
			</Popup>
		</>
	);
};
