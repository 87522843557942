import { FC } from 'react';
import { Helmet } from 'react-helmet';

interface FAQItemProps {
	text: string;
	title: string;
}

export const FAQItem: FC<FAQItemProps> = ({ text, title }) => {
	return (
		<>

			<div className='flex-col flex w-full gap-1'>
				<h2 className=' text-3xl font-semibold	'>{title}</h2>
				<p>{text}</p>
			</div>
		</>
	);
};
