import React, { FC } from 'react';
import Heading from 'components/Heading/Heading';

export interface Statistic {
	id: string;
	heading: string;
	subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
	{
		id: '1',
		heading: '5 681 000 houses',
		subHeading: 'Er zijn momenteel iets meer dan 5 681 000 huizen in België.',
	},
	{
		id: '2',
		heading: 'Minder dan 1% te koop',
		subHeading: 'Van die 5,6 miljoen huizen staat minder dan 1% op dit ogenblik te koop',
	},
	{
		id: '3',
		heading: '99% te activeren',
		subHeading: 'De slapende vastgoed extra activeren is onze missie',
	},
];

export interface SectionStatisticProps {
	className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = '' }) => {
	return (
		<div className={`nc-SectionStatistic relative ${className}`}>
			<Heading desc=' We willen met deze nieuwe benadering van Immo een stuk van de markt activeren die momenteel “slaapt”.'>
				🚀 Fast Facts
			</Heading>
			<div className='grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8'>
				{FOUNDER_DEMO.map((item) => (
					<div
						key={item.id}
						className='p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'
					>
						<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
							{item.heading}
						</h3>
						<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
							{item.subHeading}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default SectionStatistic;
