import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { DB } from './firestore';
import { getListingById } from './getListings';

export const getUser = async (id) => {
	const docRef = doc(DB, 'users', id);
	const _userRef = await getDoc(docRef);
	return _userRef.data();
};

export const updateUser = async (user) => {
	const docRef = doc(DB, 'users', user.id);
	await setDoc(docRef, { ...user }, { merge: true });
};

export const getUserListings = async (id) => {
	const docRef = collection(DB, 'users', id, 'listings');
	const listingsRef = await getDocs(docRef);
	let listingsIds = [];
	if (!listingsRef.empty) {
		await listingsRef.forEach(async (group) => {
			listingsIds.push(group.data());
		});
	}
	let listings = [];

	for (let ind = 0; ind < listingsIds.length; ind++) {
		const { id } = listingsIds[ind];
		const _listing = await getListingById(id);
		if (_listing) {
			listings.push(_listing);
		}
	}

	return listings;
};

export const createNewUserBid = async (bid) => {
	const bidListingRef = doc(DB, 'users', bid.userId, 'bids', bid.listingId);
	const bidListingSnap = await getDoc(bidListingRef);

	if (bidListingSnap.exists()) {
		const _userBid = bidListingSnap.data();
		const _newBids = [bid.id, ..._userBid.bids];
		await setDoc(bidListingRef, { bids: _newBids }, { merge: true });
	} else {
		await setDoc(bidListingRef, { listingId: bid.listingId, bids: [bid.id] });
	}
};
