export const calculateSouthwestPosition = (radius: number, bounds: any) => {
	const earthRadius = 6371; // in kilometers
	const distance = radius; // convert radius to kilometers
	const bearing = 225; // southwest direction in degrees
	const lat1 = (bounds.latitude * Math.PI) / 180; // convert to radians
	const lng1 = (bounds.longitude * Math.PI) / 180; // convert to radians
	const lat2 = Math.asin(
		Math.sin(lat1) * Math.cos(distance / earthRadius) +
			Math.cos(lat1) *
				Math.sin(distance / earthRadius) *
				Math.cos((bearing * Math.PI) / 180)
	);
	const lng2 =
		lng1 +
		Math.atan2(
			Math.sin((bearing * Math.PI) / 180) *
				Math.sin(distance / earthRadius) *
				Math.cos(lat1),
			Math.cos(distance / earthRadius) - Math.sin(lat1) * Math.sin(lat2)
		);
	const southwestLatitude = (lat2 * 180) / Math.PI; // convert back to degrees
	const southwestLongitude = (lng2 * 180) / Math.PI; // convert back to degrees

	return { lat: southwestLatitude, lng: southwestLongitude };
};

export const calculateNortheastPosition = (radius: number, bounds: any) => {
	const earthRadius = 6371; // in kilometers
	const distance = radius; // convert radius to kilometers
	const bearing = 45; // northeast direction in degrees
	const lat1 = (bounds.latitude * Math.PI) / 180; // convert to radians
	const lng1 = (bounds.longitude * Math.PI) / 180; // convert to radians
	const lat2 = Math.asin(
		Math.sin(lat1) * Math.cos(distance / earthRadius) +
			Math.cos(lat1) *
				Math.sin(distance / earthRadius) *
				Math.cos((bearing * Math.PI) / 180)
	);
	const lng2 =
		lng1 +
		Math.atan2(
			Math.sin((bearing * Math.PI) / 180) *
				Math.sin(distance / earthRadius) *
				Math.cos(lat1),
			Math.cos(distance / earthRadius) - Math.sin(lat1) * Math.sin(lat2)
		);
	const northeastLatitude = (lat2 * 180) / Math.PI; // convert back to degrees
	const northeastLongitude = (lng2 * 180) / Math.PI; // convert back to degrees


	return { lat: northeastLatitude, lng: northeastLongitude };
};
