import React, { FC, useState } from 'react';
import ButtonCircle from 'shared/Button/ButtonCircle';
import rightImg from 'images/SVG-subcribe2.png';
import NcImage from 'shared/NcImage/NcImage';
import Badge from 'shared/Badge/Badge';
import Input from 'shared/Input/Input';
import { DB } from 'utils/firestore';

import { doc, setDoc } from 'firebase/firestore';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

export interface SectionSubscribe2Props {
	className?: string;
	isHome?: boolean;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({
	className = '',
	isHome,
}) => {
	const [email, setEmail] = useState('');

	const [isSending, setIsSending] = useState(false);

	const [isDone, setIsDone] = useState(false);

	const sentEmail = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSending(true);
		if (email.trim() !== '') {
			await setDoc(doc(DB, 'applyAmbassadeurs', email), {
				email: email,
				date_added: new Date(),
			});
		}
		setEmail('');
		setIsDone(true);
		setIsSending(false);
	};

	const updateEmail = (value: React.SetStateAction<string>) => {
		if (isDone) {
			setIsDone(false);
		}

		setEmail(value);
	};

	return (
		<div
			className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
			data-nc-id='SectionSubscribe2'
		>
			<div className='flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5'>
				<h2 className='font-semibold text-4xl'>
					Meld je aan om ambassadeur te worden 🎉
				</h2>
				<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
					We zijn enthousiast dat je mee onze missie wil uitdragen! We
					selecteren onze ambassadeurs nauwgezet.
				</span>
				<ul className='space-y-4 mt-10'>
					<li className='flex items-center space-x-4'>
						<Badge name='01' />
						<span className='font-medium text-neutral-700 dark:text-neutral-300'>
							Meld je aan
						</span>
					</li>
					<li className='flex items-center space-x-4'>
						<Badge color='red' name='02' />
						<span className='font-medium text-neutral-700 dark:text-neutral-300'>
							We contacteren je voor een intake gesprek
						</span>
					</li>
				</ul>
				{isHome ? (
					<>
						<span className='block mt-5 text-neutral-500 dark:text-neutral-400'>
							Ben jij net zo enthousiast als wij over Immo Mystique? Help ons
							mee te groeien en word ambassadeur.
						</span>
						<ButtonPrimary href={'/ambassadeurs'} className='py-1 mt-2'>
							Meer info
						</ButtonPrimary>
					</>
				) : (
					<form
						onSubmit={(e) => sentEmail(e)}
						className='mt-10 relative max-w-sm'
					>
						<Input
							required
							aria-required
							placeholder='E-mailadres'
							type='email'
							rounded='rounded-full'
							value={email}
							changeValue={updateEmail}
						/>
						<ButtonCircle
							type='submit'
							className='absolute transform top-1/2 -translate-y-1/2 right-[5px]'
							disabled={isSending}
						>
							{isDone && email === '' ? (
								<i className='las las la-check  text-xl'></i>
							) : (
								<i className='las la-arrow-right text-xl'></i>
							)}
						</ButtonCircle>
					</form>
				)}
			</div>
			<div className='flex-grow'>
				<NcImage src={rightImg} />
			</div>
		</div>
	);
};

export default SectionSubscribe2;
