import { FC, createContext, useState, useContext } from 'react';
import { ReactNode } from 'react';

export interface HeaderFilterProps {
	children: ReactNode;
}

type SpecsTypes = {
	slaapkamers?: number;
	badkamers?: number;
	toilet?: number;
};

export type Filter = {
	location?: any;
	radius: number;
	specs?: SpecsTypes;
	active?: boolean;
};
export type FilterContextType = {
	filter: Filter;
	setFilter: (filter: Filter) => void;
};

const FilterContext = createContext<FilterContextType>({
	filter: {
		radius: 25,
		active: false,
		location: {
			name: 'Brussel',
			latitude: 50.84673,
			longitude: 4.352502,
		},
	},
	setFilter: (filter) => console.log('HERE', filter),
});

export const FilterProvider: FC<HeaderFilterProps> = ({ children }) => {
	const [filter, setFilter] = useState<Filter>({
		radius: 25,
		active: false,
		location: {
			name: 'Brussel',
			latitude: 50.84673,
			longitude: 4.352502,
		},
	});

	return (
		<FilterContext.Provider value={{ filter: filter, setFilter: setFilter }}>
			{children}
		</FilterContext.Provider>
	);
};

export const useFilter = () => {
	return useContext(FilterContext);
};
