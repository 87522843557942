import { FC, FormEvent, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';

export interface AddListingForm2Props {
	className?: string;
	listing?: any;
	setListing: (e: FormEvent, listing: any) => void;
	setFormType: (nr: number) => void;
}

const AddListingForm2: FC<AddListingForm2Props> = ({
	className = '',
	listing,
	setListing,
	setFormType,
}) => {
	const [adress, setAdress] = useState(listing.adress ? listing.adress : '');

	const [adressLatLng, setAdressLatLng] = useState<any>(
		listing.latitude && listing.longitude
			? { lat: listing.latitude, lng: listing.longitude }
			: {}
	);

	const [err, setErr] = useState<boolean>(false);

	const handleSelect = (addrss: any) => {
		setAdress(addrss);
		geocodeByAddress(addrss)
			.then((results: any) => getLatLng(results[0]))
			.then((latLng: any) => {
				setAdressLatLng(latLng);
			})
			.catch((error: any) => console.error('Error', error));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (!adressLatLng.lat && !adressLatLng.lng) {
			setErr(true);
		} else {
			setErr(false);
			setListing(e, {
				adress: adress,
				tags: [adress.split(',')[1]],
				latitude: adressLatLng.lat,
				longitude: adressLatLng.lng,
			});
		}
	};

	return (
		<>
			<form
				className='grid grid-cols-1 gap-6 w-full'
				onSubmit={(e) => handleSubmit(e)}
			>
				<div>
					<h2 className='text-lg'>Adres van uw pand </h2>
					<p>Dit wordt niet weergegeven op het platform</p>
				</div>
				<PlacesAutocomplete
					value={adress}
					onChange={setAdress}
					onSelect={handleSelect}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}: any) => (
						<div>
							{err && <p>Geen locatie gevonden </p>}
							<input
								{...getInputProps({
									placeholder: 'Zoek het adres van je pand',
									className: 'location-search-input',
								})}
								required
							/>
							<div className='autocomplete-dropdown-container'>
								{loading && <div>Loading...</div>}
								{suggestions.map((suggestion: any) => {
									const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
										? {
												border: 'solid 1px #45D62C',
												padding: 6,
												cursor: 'pointer',
												borderRadius: 20,
												marginTop: 10,
										  }
										: {
												border: 'solid 1px #ffffff',
												padding: 6,
												cursor: 'pointer',
												borderRadius: 20,
												marginTop: 10,
										  };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>

				{/* <div className='flex gap-6'>
					<label className='block'>
						<Label>Nummer</Label>

						<Input
							required
							placeholder='Nummer van uw pand'
							type='number'
							className='mt-1'
							value={houseNumber}
							changeValue={setHouseNumber}
							min={0}
						/>
					</label>

					<label className='block'>
						<Label>Postcode</Label>

						<Input
							required
							placeholder='Potscode van uw pand'
							type='number'
							className='mt-1'
							min={1000}
							max={9999}
							value={zipcode}
							changeValue={setZipcode}
						/>
					</label>

					<label className='block'>
						<Label>Stad</Label>

						<Input
							required
							placeholder='Stad waarin u pand zoch bevind'
							type='text'
							className='mt-1'
							value={city}
							changeValue={setCity}
						/>
					</label>

					<label className='block'>
						<Label>Provincie</Label>

						<select
							value={provincie}
							onChange={(e) => setProvincie(e.currentTarget.value)}
							required
						>
							<option value='West-Vlaanderen'>West-Vlaanderen</option>
							<option value='Oost-Vlaanderen'>Oost-Vlaanderen</option>
							<option value='Antwerpen'>Antwerpen</option>
							<option value='Vlaams-Brabant'>Vlaams-Brabant</option>
							<option value='Waals-Brabant'>Waals-Brabant</option>
							<option value='Limburg'>Limburg</option>
							<option value='Henegouwen'>Henegouwen</option>
							<option value='Luik'>Luik</option>
							<option value='Namen'>Namen</option>
							<option value='Luxemburg'>Luxemburg</option>
						</select>
					</label>
				</div> */}
				<div className='flex justify-between'>
					<ButtonSecondary onClick={() => setFormType(0)}>
						Vorige stap
					</ButtonSecondary>

					<ButtonPrimary type='submit'>Volgende stap</ButtonPrimary>
				</div>
			</form>
		</>
	);
};

export default AddListingForm2;
