import React, { FC } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { DEMO_STAY_LISTINGS } from 'data/listings';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';

export interface StayCardProps {
	className?: string;
	data?: any;
	size?: 'default' | 'small';
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
	size = 'default',
	className = '',
	data = DEMO_DATA,
}) => {
	const renderSliderGallery = () => {
		return (
			<div className='relative w-full'>
				<GallerySlider
					listingId={data.id}
					uniqueID={`StayCard_${data.id}`}
					ratioClass='aspect-w-4 aspect-h-3 '
					galleryImgs={data.photos}
					href={`/panden/${data.id}`}
				/>
				{/* <BtnLikeIcon isLiked={false} className='absolute right-3 top-3 z-[1]' /> */}
			</div>
		);
	};

	const renderContent = () => {
		return (
			<div className={size === 'default' ? 'p-4 space-y-4' : 'p-3 space-y-2'}>
				<div className='space-y-2'>
					<span className='text-sm text-neutral-500 dark:text-neutral-400'>
						in {data.adress.split(',')[1].trim()}
					</span>
					<div className='flex items-center space-x-2'>
						<h2
							className={` font-medium capitalize ${
								size === 'default' ? 'text-lg' : 'text-base'
							}`}
						>
							<span className='line-clamp-1'>{data.title}</span>
						</h2>
					</div>
				</div>
				<div className='w-14 border-b border-neutral-100 dark:border-neutral-800'></div>
				<div className='flex justify-between items-center'>
					<div className='hidden sm:flex flex-wrap space-x-2 justify-between items-center max-w-sm'>
						<div className='flex items-center space-x-3'>
							<i className='las la-expand text-lg'></i>
							<span className='text-sm text-neutral-500 dark:text-neutral-400'>
								{data.size} m²
							</span>
						</div>

						<div className='flex items-center space-x-3'>
							<i className='las la-home text-lg'></i>
							<span className='text-sm text-neutral-500 dark:text-neutral-400'>
								{data.livingspace} m²
							</span>
						</div>

						<div className='flex items-center space-x-3'>
							<i className='las la-bed text-lg'></i>
							<span className='text-sm text-neutral-500 dark:text-neutral-400'>
								{data.bedrooms} x
							</span>
						</div>
					</div>
				</div>
				<div className='w-14 border-b border-neutral-100 dark:border-neutral-800'></div>
				<p className='line-clamp-3'>{data.about}</p>
			</div>
		);
	};

	return (
		<div
			className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
			data-nc-id='StayCard'
		>
			{renderSliderGallery()}
			<Link to={`/panden/${data.id}`}>{renderContent()}</Link>
		</div>
	);
};

export default StayCard;
